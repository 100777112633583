import React, { useState, useEffect } from "react";
// @mui
import { useTheme } from "@mui/material";

function HeroSection({ white = false, zero = false }) {
  const theme = useTheme();

  return (
    <div style={{ background: white && theme.palette.background.paper }}>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14430.52348567741!2d55.4423087!3d25.2829999!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5e55fd088f45%3A0xb71ae62ff5951c27!2sNajmat%20Al%20Najah%20Buses%20Transport!5e0!3m2!1sen!2sae!4v1704703304095!5m2!1sen!2sae"
        width="100%"
        height="450"
        style={{ border: 0 }}
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
}

export default HeroSection;
