import React from "react";
import { styled } from "@mui/material/styles";

const RootStyle = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  minHeight: "100vh",
}));

function HeroSection() {
  return <RootStyle>HeroSection</RootStyle>;
}

export default HeroSection;
