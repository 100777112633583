import { useEffect } from "react";
// @mui
import { Stack, FormLabel, Container } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { RHFTextField } from "components/hook-form";

import { RootFilterStyle } from "./styles";
import { BusRouteData } from "_mock/BusData";

function getAreasInDivision(data, divisionCode) {
  let areas = [];
  areas.push({ label: "All", value: "0" });

  if (divisionCode === "0") {
    areas = areas.concat(
      data.flatMap((route) =>
        route.children.map((area) => ({ label: area.area, value: area.areacode }))
      )
    );
  } else {
    const division = data.find((route) => route.divisioncode === divisionCode);
    areas = areas.concat(
      division ? division.children.map((area) => ({ label: area.area, value: area.areacode })) : []
    );
  }

  return areas;
}

function getRoutesInArea(data, divisionCode, areaCode) {
  let routes = [];
  routes.push({ label: "All", value: "0" });

  if (divisionCode === "0" && areaCode === "0") {
    routes = routes.concat(
      data.flatMap((route) =>
        route.children.flatMap((area) =>
          area.children.map((route) => ({ label: route.route, value: route.routecode }))
        )
      )
    );
  } else {
    const division = data.find((route) => route.divisioncode === divisionCode);
    if (division) {
      if (areaCode === "0") {
        routes = routes.concat(
          division.children.flatMap((area) =>
            area.children.map((route) => ({ label: route.route, value: route.routecode }))
          )
        );
      } else {
        const area = division.children.find((area) => area.areacode === areaCode);
        routes = routes.concat(
          area ? area.children.map((route) => ({ label: route.route, value: route.routeCode })) : []
        );
      }
    }
  }

  return routes;
}

export default function RouteFilter() {
  const methods = useFormContext();
  const { getValues, watch, setValue } = methods;

  useEffect(() => {
    setValue("areacode", "0");
    setValue("routecode", "0");
  }, [watch("division")]);

  return (
    <RootFilterStyle>
      <Container>
        <Stack
          direction={{ xs: "column", md: "row" }}
          spacing={3}
          alignItems="center"
          position="relative"
        >
          <div style={{ width: "100%" }}>
            <FormLabel>Division</FormLabel>
            <RHFTextField
              name="division"
              select
              options={Options}
              variant="standard"
              placeholder="Select Division"
              SelectProps={{
                sx: {
                  fontSize: 16,
                  textTransform: "lowercase",
                },
              }}
            />
          </div>
          <div style={{ width: "100%" }}>
            <FormLabel>Area</FormLabel>
            <RHFTextField
              name="areacode"
              select
              options={getAreasInDivision(BusRouteData, watch("division"))
              }
              variant="standard"
              placeholder="Select Division"
              SelectProps={{
                sx: {
                  fontSize: 16,
                  textTransform: "lowercase",
                },
              }}
            />
          </div>
          <div style={{ width: "100%" }}>
            <FormLabel>Route</FormLabel>
            <RHFTextField
              name="routecode"
              select
              options={getRoutesInArea(BusRouteData, watch("division"), watch("areacode"))}
              variant="standard"
              placeholder="Select Division"
              SelectProps={{
                sx: {
                  fontSize: 16,
                  textTransform: "lowercase",
                },
              }}
            />
          </div>
        </Stack>
      </Container>
    </RootFilterStyle>
  );
}

const Options = [
  {
    label: "All Divisions",
    value: "0",
  },
  {
    label: "DPS Sharjah Primary",
    value: "1",
  },
  {
    label: "DPS Sharjah Senior",
    value: "2",
  },
  {
    label: "DPS Dubai",
    value: "3",
  },
  {
    label: "DPS RAK",
    value: "4",
  },
];
