import React from "react";
// @mui
import { Stack, Typography, useTheme } from "@mui/material";

// styled components
import { ImageContainer, StyledSearchButton, RootStyle, DataContainer } from "./styles";

import Filter from "./filter";

function HeroSection({ id }) {
  const theme = useTheme();
  return (
    <RootStyle id={id} sx={{ flexDirection: "column", position: "relative" }}>
      <DataContainer sx={{ mb: 15 }}>
        <Typography
          variant="h1"
          textAlign="center"
          fontWeight="700"
          fontFamily='"Merriweather", serif'
        >
          View Your Child's School Bus Route
        </Typography>
        <Typography variant="body1" textAlign="center" mt={2} mx={4}>
          We understand the importance of knowing your child's school bus route for a seamless and
          worry-free transportation experience.
        </Typography>
      </DataContainer>
      <Filter />
    </RootStyle>
  );
}

export default HeroSection;
