//images
import Features from "assets/images/hero/featurs.png";

export const WhyNBTConfig = {
  head: "Why Choose NBT?",
  desc: "Choose NBT as your transportation partner for a clear vision prioritizing the safety and comfort of our clients. With an impressive safety record and an unwavering dedication to safety at our core, we ensure comprehensive training for our drivers. Our pre-service program offers 30 hours of rigorous training, while our in-service and behind-the-wheel program provides new drivers with an extensive 80-hour training in their first year. Committed to safety education, we pledge to inform students and parents about safe school bus practices. At NBT, we pride ourselves on a first-class preventive maintenance system, fostering excellent employee relations and enabling effective communication between our drivers and logistics crew. Join us for a partnership built on safety, expertise, and dedication.",
  img: Features,
};

export const VisionConfig = {
  head: `Our Vision`,
  desc: "To be the transport provider of customers’ choice, consistent emphasis on best-in-class service, a model of excellence in the UAE, through the integration of Sustainability, technology, and industry best practices, ensuring a high level of safety, quality through innovation and maintain a long-term relationship with our clients.",
};

export const AboutConfig = {
  head: `About us`,
  desc: "Providing Safe and Secure School Bus Transportation. Our vision is to provide solutions for an increasingly congested world - keeping people moving and communities prospering. Piloted by our core keys, we are a customer-centric organization with focused flexibility, accessibility, and a solid commitment to our customers’ success. To deliver our vision we develop transportation services that enable us to make the best possible impact on the communities we serve, the environment we live in, and the economy we fuel.",
};

export const MissionConfig = {
  head: `Our Misson`,
  desc: "To ensure that every child is picked up from their location, brought to the to the school and bring them back to their house location- safely. Safety is for every passenger. We have the ability to positively impact each student’s day, so they arrive at school ready to learn and be inspired. It is something we are committed to doing every single day.",
};
export const ObjectiveConfig = {
  head: `Obejctives`,
  desc: "Providing Safe and Secure School Bus Transportation. Our vision is to provide solutions for an increasingly congested world - keeping people moving and communities prospering. Piloted by our core keys, we are a customer-centric organization with focused flexibility, accessibility, and a solid commitment to our customers’ success. To deliver our vision we develop transportation services that enable us to make the best possible impact on the communities we serve, the environment we live in, and the economy we fuel.",
};

export const SafetyConfig = {
  head: `Guiding Principles for Success`,
  desc: `Our ethos revolves around customer success, unwavering safety, mutual support, and relentless pursuit of excellence. We prioritize accountability in decisions, setting the highest standards to drive success for all stakeholders.`,
  steps: [
    {
      head: "Commitment to our customers",
      desc: "We keep our customers at the heart of everything we do. This extends beyond customer satisfaction; we focus on customer success.",
      icon: "covid:personal-hygiene-hand-sanitizer-spray",
    },
    {
      head: "Supportive of each other",
      desc: "We trust each other to deliver and work to help one another succeed.",
      icon: "solar:health-broken",
    },
    {
      head: "Dedication to safety standards",
      desc: "Always first in mind, safety is our way of life. Reliable student transportation services that maximizes operational efficiencies and cost savings without sacrificing control or accountability.",
      icon: "covid:personal-hygiene-hand-sanitizer-spray",
    },
    {
      head: "Accountability for performance",
      desc: "Every decision matters. We do the right things to drive success for our partners, which in turn will allow us to achieve our goals.",
      icon: "solar:health-broken",
    },
    {
      head: "Setting the highest standards",
      desc: "We want to be the best, continually finding new and better ways to help our partners, communities and employees succeed.",
      icon: "covid:personal-hygiene-hand-sanitizer-spray",
    },
  ],
};

export const ClientConfig = {
  head: `Our Clients`,
  desc: `Our ethos revolves around customer success, unwavering safety, mutual support, and relentless pursuit of excellence. We prioritize accountability in decisions, setting the highest standards to drive success for all stakeholders.`,
  steps: [
    {
      head: "Delhi Private School Sharjah",
      url: "https://firebasestorage.googleapis.com/v0/b/najmat-fcf50.appspot.com/o/client%2FDelhiPrivateSchool-sharjah.jpg?alt=media&token=37a14a46-690c-4b76-ad77-1f0c6f843947",
    },
    {
      head: "Delhi Private School RAK",
      url: "https://firebasestorage.googleapis.com/v0/b/najmat-fcf50.appspot.com/o/client%2FDelhiPrivateSchool-RAK.jpg?alt=media&token=188dacc1-ca7b-47be-85b1-81beee48e738",
    },
    {
      head: "Najmat Alnajah Auto Services LLC",
      url: "https://firebasestorage.googleapis.com/v0/b/najmat-fcf50.appspot.com/o/client%2FnajmatGarage.jpg?alt=media&token=99e81ffc-7cc4-49d0-b74a-a2ecc130f2be",
    },
  ],
};

export const CTAConfig = {
  head: "With a clear vision for the safety and comfort of our clients. ",
  desc: "We maintain an impressive safety record due to our uncompromising dedication to safety in all our operations. Furthermore,we ensure that all new drivers undergo a pre-service training program, providing them with at least 30 hours of training before they commence their employment.",
};

export const MSGConfig = {
  head: "Message From General Manager",
  desc: `Dear Parent,
  I hope this message finds you well.
  When we commenced providing our services during 2014, we bore in mind the Safety, Security & Sustainability for our line of business of school children & staff transportation. We moved forward in providing the best of our services according to UAE education safety protocols & standards. 
  We strived on the formation of our unique working team, complying with the quote of H. Ross Perot “When building a team, I always search first for people who love to win. If I cannot find any of those, I look for people who hate to lose”.
  Please be assured that the safety and well-being of your children remain our top priority. 
  We understand the significance of a reliable and safe transportation service, and our team is dedicated to ensuring the seamless integration of these improvements. Your feedback and cooperation are essential to the success of these changes, and we encourage you to reach out with any questions or concerns.
  We appreciate your trust in Najmat Al Najah School Bus transportation LLC (NBT), and we are committed to delivering a service that reflects the high standards of the school community in the UAE.
  “Coming together is the beginning, keeping together is progress and working together is success”
  Thank you for your continued trust in our school transportation services.

  Best regards,
  Stanley Doraiswamy
  General Manager
  `,
};

