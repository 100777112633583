import React from "react";
// @mui
import { Stack, Typography, Card, Button, useTheme, Box } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { useNavigate } from "react-router-dom";
import { handleDownloadTraForm } from "hooks/downloads";

// styled components
import { RootStyle, WhiteContainer } from "./styles";
import Iconify from "components/iconify";
import useResponsive from "hooks/useResponsive";

const DefaultHeading = "This is the Heading";
const DefaultDescription =
  "Et cupidatat sint id sunt sunt anim adipisicing irure amet ut qui do. Culpa consectetur culpa qui aliquip duis. Aliqua pariatur magna fugiat consequat. Consectetur non laborum veniam esse est nulla. Occaecat laborum irure Lorem et voluptate culpa ad adipisicing. Cupidatat esse deserunt sint culpa proident non deserunt est consectetur ut. Laboris consectetur exercitation tempor nulla consectetur incididunt commodo nulla anim ad aliquip aliquip veniam consequat.";

function GmMessage({ heading = DefaultHeading, description = DefaultDescription, img }) {
  const navigate = useNavigate();
  const theme = useTheme();
  const mdDown = useResponsive("down", "md");
  const handleClickContact = () => {
    navigate("/contact-us");
  };

  return (
    <RootStyle sx={{ px: 1, minHeight: 2, py: 10 }}>
      <Card sx={{ p: mdDown ? 2 : 5, maxWidth: mdDown ? "100%" : "80%" }}>
        <Stack
          direction={{ xs: "column", md: "row" }}
          spacing={3}
          style={{ width: "100%", height: "100%" }}
        >
          <Stack flex={1.5}>
            <Typography variant={mdDown ? "h4" : "h2"} fontWeight="700">
              {heading}
            </Typography>
            <Box component="div" mt={2} color="text.secondary">
  {description.split("\n").map((paragraph, index) => {
    // Check if the paragraph contains either of the quoted texts
    if (paragraph.includes("“Coming together is the beginning, keeping together is progress and working together is success”") || paragraph.includes("“When building a team, I always search first for people who love to win. If I cannot find any of those, I look for people who hate to lose”")) {
      return (
        <Typography key={index} variant="body1" sx={{ fontStyle: 'italic', marginBottom: 1 }}>
          {paragraph}
        </Typography>
      );
    } else {
      return (
        <Typography key={index} variant="body1">
          {paragraph}
        </Typography>
      );
    }
  })}
</Box>
          </Stack>
          {/* <Stack
            direction={{ xs: "column", md: "row" }}
            spacing={2}
            py={{ xs: 1, md: 6 }}
            justifyContent="center"
            alignItems="center"
            flex={1}
          >
            <img
              src="https://www.corporatephotographerslondon.com/wp-content/uploads/2021/07/LinkedIn_profile_photo_sample_1-300x300.jpg"
              alt="General manager"
              style={{ height: 260 }}
            />
          </Stack> */}
        </Stack>
      </Card>
      <WhiteContainer />
    </RootStyle>
  );
}

export default GmMessage;
