import React, { Suspense, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

// @mui
import { Box, Divider, Button, Container } from '@mui/material';
import { styled } from '@mui/material/styles';

// components
import { Headings } from '../../components/Strings';

// sections
import TransportFormParent from '../../Sections/Forms/TransportFormParent';

// extra functions
import { bgBlur } from '../../utils/cssStyles';
import StyledHeaders from '../../components/ScreenHeader';
import Typography from 'theme/overrides/Typography';

// ----------------------------------------------------------------------

const StyledHead = styled(Box)(({ theme }) => {
  return {
    ...bgBlur({ color: theme.palette.background.paper }),
    boxShadow: 'none',
    paddingBottom: theme.spacing(3),
  };
});

// ----------------------------------------------------------------------

export default function TransportForm() {
  // cached api request
  return (
    <>
      <Helmet>
        <title>Transport Form | NTB </title>
      </Helmet>

      {/* <div style={{ marginBottom: 24, padding: '0px 0px' , marginTop: 18}}>
        <StyledHeaders title="Transport Form for parent">
        </StyledHeaders>
      </div> */}
      <Container maxWidth="xl" sx={{ px: '40px !important' }}>
        <TransportFormParent />
      </Container>
    </>
  );
}
