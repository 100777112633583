import { useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
// @mui
import { styled, alpha, useTheme } from "@mui/material/styles";
import { Box, Drawer, IconButton, Stack } from "@mui/material";
// hooks
import useResponsive from "hooks/useResponsive";
// components
import Iconify from "components/iconify";
import Scrollbar from "components/scrollbar";
import NavSection from "./searchLayout/navsection";

//
import { navConfigDrawer as navConfig } from "./config";

// ----------------------------------------------------------------------

const NAV_WIDTH = 340;

const StyledLogoContainer = styled("div")(({ theme }) => ({
  padding: theme.spacing(2),
  maxWidth: "100%",
  // height: 53,
  background: `linear-gradient(to bottom right, ${theme.palette.primary.main}, ${alpha(
    theme.palette.secondary.main,
    0.6
  )})`,
  borderRadius: theme.spacing(8),
  boxShadow: theme.customShadows.primary,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

// ----------------------------------------------------------------------

export default function Nav({ openNav, handleDrawerClose }) {
  const { pathname } = useLocation();
  const isDesktop = useResponsive("up", "lg");
  const theme = useTheme();

  useEffect(() => {
    if (openNav) {
      handleDrawerClose();
    }
  }, [pathname]);

  const renderContent = (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        background: theme.palette.background.paper,
        overflow: "hidden",
        borderRight: (theme) => `1px solid ${theme.palette.divider} !important`,
      }}
    >
      <Stack p={3} alignItems="flex-end">
        <div>
          <IconButton sx={{ border: (theme) => `1px solid ${theme.palette.divider} !important` }} onClick={handleDrawerClose}>
            <Iconify icon="akar-icons:cross" />
          </IconButton>
        </div>
      </Stack>
      <Scrollbar
        sx={{
          height: 1,
          "& .simplebar-content": { height: 1, display: "flex", flexDirection: "column", maxHeight: "84vh" },
          bgcolor: (theme) => theme.palette.background.paper,
        }}
      >
        <NavSection data={navConfig} />
        <Box sx={{ flexGrow: 1 }} />
      </Scrollbar>
    </div>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: {
          lg: NAV_WIDTH,
        },
      }}
    >
      <Drawer
        open={openNav}
        onClose={handleDrawerClose}
        // dir={language === "ar" ? "rtl" : "ltr"}
        ModalProps={{
          keepMounted: true,
        }}
        PaperProps={{
          sx: { width: NAV_WIDTH },
        }}
      >
        {renderContent}
      </Drawer>
    </Box>
  );
}
