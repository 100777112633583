import { Card } from "@mui/material";
import { styled } from "@mui/material/styles";

export const RootStyle = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  marginTop: theme.spacing(4),
  overflow: "visible",
  zIndex: 2,
  paddingBottom: theme.spacing(8),

  [theme.breakpoints.up("md")]: {
    paddingTop: theme.spacing(4),
  },
  [theme.breakpoints.up("lg")]: {
    paddingTop: theme.spacing(4),
  },
}));

export const RootFilterStyle = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: theme.palette.background.paper,
  zIndex: 2,
  padding: theme.spacing(4),
  width: "100%",

  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(5, 5),
  },
  [theme.breakpoints.up("lg")]: {
    padding: theme.spacing(1, 10),
    position: "fixed",
    top: 80,
  },
}));

export const BusItemStyle = styled(Card)(({ theme }) => ({
  padding: theme.spacing(2),
  boxShadow: "none",
  width: "100%",
  transition: "0.2s linear",
  cursor: "pointer",
  "&:hover": { boxShadow: theme.boxShadows.xxl, transform: "scale(1.01)" },
  "&:active": {
    boxShadow: "none",
    transform: "scale(0.995)",
    transition: "0.09s ease-in-out",
  },
}));
