import { Stack } from "@mui/material";
import { styled } from "@mui/material/styles";

// image
import HeroImage from "assets/images/hero/hero-updated-01.svg";
import HeroImage2 from "assets/images/hero/hero-updated-02.svg";

export const RootStyle = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  background: theme.palette.background.paper,
}));

export const ImageContainer = styled("div")(({}) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "800px",
  flex: 1,
  backgroundImage: `url(${HeroImage2})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "100%",
  backgroundPosition: "bottom",
}));

export const ImageContainer2 = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "800px",
  flex: 1,
  backgroundImage: `url(${HeroImage})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "100%",
  backgroundPosition: "bottom",
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

export const DataContainer = styled(Stack)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "absolute",
  left: "50%",
  transform: "translate(-50%)",
  top: 180,
  maxWidth: 600,
  [theme.breakpoints.down("md")]: {
    maxWidth: "100%",
    width: "100%",
    padding: theme.spacing(4),
  },
}));

export const SliderImage = styled("div")(({ theme }) => ({
  height: 600,
  width: "100%",
  backgroundColor: theme.palette.primary.main,
  [theme.breakpoints.down("md")]: {
    height: 400,
  },
  [theme.breakpoints.down("sm")]: {
    height: 200,
  },
}));
