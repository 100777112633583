import React from "react";
import { useTheme } from "@mui/material/styles";
// sections
import { HeroSection } from "Sections/BusRoutes";
import { SingleColFeature } from "Sections/features";

import { WhyNBTConfig } from "./config";

function Home() {
  const theme = useTheme();
  return (
    <>
      <HeroSection id="TheHero" />
      <div style={{ height: 60 }} />
      <SingleColFeature heading={WhyNBTConfig.head} description={WhyNBTConfig.desc} />
    </>
  );
}

export default Home;
