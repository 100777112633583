//images
import Features from "assets/images/hero/featurs.png";

export const RealTimeTracking = {
  head: "Facility for Real time tracking",
  desc: "The platform offers real time GPS tracking of multiple vehicles in a web browser. A live view of the entire vehicle fleet gives us control, the best vehicle can be dispatched for a job and specific vehicles can be followed when required. Alerts and notifications are instantly available in the application.",
  img: Features,
};

export const VisionConfig = {
  head: `Alerts and notifications`,
  desc: "Any event in the platform can generate alerts in the application, over email or SMS. For mobile operators we can configure notifications over SMS and email for events that require immediate attention, such as SOS, vehicle theft, high temperature, speeding, excessive idling, off hours vehicle usage or geofence violations.",
};

export const ReportConfig = {
  head: `Reporting`,
  desc: "Reports for most common business requirements are delivered with GpsGate Server to help us analyze and improve your business. Customers can use the GpsGate Reporting framework to configure additional reports based on any data in the platform. Reports can be presented directly in the web browser or scheduled and sent over email.",
};

export const SafetyConfig = {
  head: `And Many More`,
  desc: `Our ethos revolves around customer success, unwavering safety, mutual support, and relentless pursuit of excellence. We prioritize accountability in decisions, setting the highest standards to drive success for all stakeholders.`,
  steps: [
    {
      head: `Reporting`,
      desc: "Reports for most common business requirements are delivered with GpsGate Server to help us analyze and improve your business. Customers can use the GpsGate Reporting framework to configure additional reports based on any data in the platform. Reports can be presented directly in the web browser or scheduled and sent over email.",
      icon: "solar:health-broken",
    },
    {
      head: "Vehicle Maintenance",
      desc: "Our dedicated fleet maintenance ERP keeps track of when our vehicles are due for corrective and preventive maintenance. It shows a maintenance status overview and sends notifications when vehicles is due for service.",
      icon: "covid:personal-hygiene-hand-sanitizer-spray",
    },
    {
      head: "RTA Certified Drivers",
      desc: "The Drivers are certified for the safety and good driving practices by the RTA. This ensures no harsh driving, speeding and unnecessary idling of the engine. By this the vehicle wear is reduced and save money by minimizing the environmental impact of the vehicle fleet.",
      icon: "solar:health-broken",
    },
    {
      head: "Driver Journal",
      desc: "Use Driver Journal to keep track of your business trips. You can categorize different trips and add notes to them for your reports. A great plugin to support tax authority requirements and company regulations.",
      icon: "covid:personal-hygiene-hand-sanitizer-spray",
    },
    {
      head: "Device support",
      desc: "GpsGate Server supports dedicated tracking devices, Windows computers, Windows Mobile devices, Android, iPhone and Blackberry smart phones. This makes the platform future proof, flexible and suitable for large implementations with multiple device types.",
      icon: "solar:health-broken",
    },
  ],
};

export const CTAConfig = {
  head: "With a clear vision for the safety and comfort of our clients. ",
  desc: "We maintain an impressive safety record due to our uncompromising dedication to safety in all our operations. Furthermore,we ensure that all new drivers undergo a pre-service training program, providing them with at least 30 hours of training before they commence their employment.",
};


export const Serviceonfig = {
  head: "Our Services ",
  desc:  `Providing Safe and Secure School Bus Transportation. Our vision is to provide solutions for an increasingly congested world - keeping people moving and communities prospering. Piloted by our core keys, we are a customer-centric organization with focused flexibility, accessibility, and a solid commitment to our customers’ success. To deliver our vision we develop transportation services that enable us to make the best possible impact on the communities we serve, the environment we live in, and the economy we fuel. 

  We maintain an impressive safety record due to our uncompromising dedication to safety in all our operations. Furthermore,we ensure that all new drivers undergo a pre-service training program, providing them with at least 30 hours of training before they commence their employment.`
  ,
};
