import * as Yup from "yup";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
// @mui
import { useTheme } from "@mui/material/styles";
import { Card, Stack, FormLabel } from "@mui/material";

// form

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, RHFTextField } from "../../components/hook-form";
import Iconify from "components/iconify";

import { StyledSearchButton } from "./styles";

export default function RouteFilter() {
  const theme = useTheme();
  const isMd = theme.breakpoints.up("md");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  // form
  const LoginSchema = Yup.object().shape({
    division: Yup.string().required(),
  });

  const defaultValues = {
    division: "0",
    areacode: "",
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const { handleSubmit } = methods;
  // form

  const onSubmit = async () => {
    setLoading(true);
    setTimeout(() => {
      navigate("/services/bus-routes/search");
      setLoading(false);
    }, 800);
  };

  return (
    <Card
      sx={{
        p: 1,
        pl: 6,
        width: "100%",
        maxWidth: 800,
        borderRadius: isMd && 150,
        position: "absolute",
        bottom: "-50px",
        border: `1px solid ${theme.palette.divider}`,
      }}
    >
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack
          direction={{ xs: "column", md: "row" }}
          spacing={3}
          alignItems="center"
          position="relative"
        >
          <div style={{ width: "100%" }}>
            <FormLabel>Division</FormLabel>
            <RHFTextField
              name="division"
              select
              options={Options}
              variant="standard"
              placeholder="Select Division"
              SelectProps={{
                sx: {
                  fontSize: 20,
                },
              }}
            />
          </div>
          <div style={{ width: "100%" }}>
            <FormLabel>Area</FormLabel>
            <RHFTextField
              name="areacode"
              select
              options={Options}
              variant="standard"
              placeholder="Select Division"
              SelectProps={{
                sx: {
                  fontSize: 20,
                },
              }}
            />
          </div>

          <StyledSearchButton type="submit" color="primary" variant="contained" loading={loading}>
            <Iconify icon="iconoir:search" />
          </StyledSearchButton>
        </Stack>
      </FormProvider>
    </Card>
  );
}

const Options = [
  {
    label: "All Divisions",
    value: "0",
  },
  {
    label: "DPS Sharjah Primary",
    value: "1",
  },
  {
    label: "DPS Sharjah Senior",
    value: "2",
  },
  {
    label: "DPS RAK",
    value: "3",
  },
];
