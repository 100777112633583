import React, { useEffect, useState } from "react";
// @mui
import {
  Container,
  List,
  ListSubheader,
  ListItem,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import * as XLSX from "xlsx";
import { DataGrid } from "@mui/x-data-grid";
import QRImage from "assets/documents/skiply-qr-code.svg";

// styled components
import FeeStructureSrc from "assets/documents/Data-najmath.xlsx";

const fetchData = async (setState) => {
  try {
    const response = await fetch(FeeStructureSrc);
    const blob = await response.blob();

    const reader = new FileReader();
    reader.onload = (event) => {
      const data = event.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[2]; // Assuming you want the first sheet
      const worksheet = workbook.Sheets[sheetName];
      const excelData = XLSX.utils.sheet_to_json(worksheet);
      if (excelData) {
        const FinalData = excelData.map((dataitem, index) => ({ id: index + 1, ...dataitem }));
        setState(FinalData);
      }
    };
    reader.readAsBinaryString(blob);
  } catch (error) {
    console.error("Error occurred while reading the file:", error);
  }
};

const CONFIG = {
  year: "2023-2024",
};

function FeeSection({ id, white = true }) {
  const theme = useTheme();
  const [ExcelData, setExcelData] = useState([]);
  const [TableHeaders, setTableHeaders] = useState([]);
  console.log("🚀 ~ file: Feesection.jsx:53 ~ FeeSection ~ TableHeaders:", ExcelData);

  useEffect(() => {
    if (!ExcelData.length) {
      fetchData(setExcelData);
    }

    if (ExcelData.length) {
      const KEYARR = Object.keys(ExcelData[1]);
      const FINALDATA = KEYARR.filter(key => key !== 'id').map((KEY) => ({
        headerName: KEY.toString().toLowerCase(),
        field: KEY,
        flex: KEY.includes("Description") ? 4 : 1,
        display: "flex",
        flexWrap: "wrap",
        whiteSpace: "break-spaces",
        minWidth: 120,
        sortable: false,
      }));
      setTableHeaders(FINALDATA);
    }
  }, [ExcelData]);


  return (
    <div style={{ background: white && theme.palette.background.paper }}>
      <Container maxWidth="xl" sx={{ py: 10 }}>
        <Typography
          variant="h1"
          textAlign="center"
          fontWeight="700"
          fontFamily='"Merriweather", serif'
        >
          Payment Modes
        </Typography>

        {/* mode 1 */}
        <div style={{ marginTop: 48 }}>
          <Typography
            variant="h4"
            textAlign="left"
            fontWeight="700"
            fontFamily='"Merriweather", serif'
          >
            1. Payment Through Skiply
          </Typography>
          <Typography variant="body1">
            Kindly scan the QR code provided below to download <b>Skiply App</b>.
          </Typography>
          <img src={QRImage} style={{ height: 320, width: 320, marginTop: 48 }} />
        </div>

        

        

        {/* mode 2 */}
        <div style={{ marginTop: 48 }}>
          <Typography
            variant="h4"
            textAlign="left"
            fontWeight="700"
            fontFamily='"Merriweather", serif'
          >
            2. Credit card
          </Typography>
          <Typography variant="body1">
          Kindly pay using credit card at Transport fee counter at school
          </Typography>
        </div>
        {/* mode 3 */}
        <div style={{ marginTop: 48 }}>
          <Typography
            variant="h4"
            textAlign="left"
            fontWeight="700"
            fontFamily='"Merriweather", serif'
          >
            3. Cash/Cheque Payments
          </Typography>
          <Typography variant="body1">
            May be Paid through cash / account payee cheque favouring 'NAJMAT AL NAJAH SCHOOL BUS
            TRANSPORTATION LLC' at our fee counter at the school.
          </Typography>
        </div>

        {/* mode 4 */}
        <div style={{ marginTop: 48 }}>
          <Typography
            variant="h4"
            textAlign="left"
            fontWeight="700"
            fontFamily='"Merriweather", serif'
          >
            4. Bank Transfer
          </Typography>
          {TableHeaders && (
            <Paper
              sx={{
                mt: 2,
                "& .MuiDataGrid-cellContent": {
                  whiteSpace: "break-spaces",
                  padding: "8px 0px",
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                  textTransform: "uppercase",
                  fontSize: 12,
                },
              }}
            >
              <DataGrid
                rowSelection={false}
                rows={ExcelData}
                getRowHeight={() => "auto"}
                columns={TableHeaders}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 10 },
                  },
                }}
                disableColumnMenu
              />
            </Paper>
          )}

          <Typography variant="body1" style={{ color: "red" }} mt={2}>
            <b>* IMPORTANT NOTE.</b>
          </Typography>
          <Typography variant="body1">
          Please mention the description / details in the following sequence :
          </Typography>
          <List>
            <ListItem>
              <Typography variant="body2">
              • Admission No - Student Name - Class/Section - Parent Name
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body2">
              • You are requested to strictly follow this sequence to correlate your bank transfer.
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body2">
              • Email the print of transfer detail with transaction number to update our records.
              </Typography>
            </ListItem>
          </List>
        </div>


        {/* mode 4 */}
        {/* <div style={{ marginTop: 48 }}>
          <Typography
            variant="h4"
            textAlign="left"
            fontWeight="700"
            fontFamily='"Merriweather", serif'
          >
            4. Payment by post dated cheques.
          </Typography>
          <Typography variant="body1">
            For your convenience, you may pay transport fees for whole year in lumpsum either in
            cash or by current dated cheque or bank transfer on or before due date of term 1 fees.
          </Typography>
        </div> */}
      </Container>
    </div>
  );
}

export default FeeSection;


