import React from "react";
import { useTheme } from "@mui/material/styles";
// sections
import { HeroSection } from "Sections/Services";
import { FeatureWithSteps, SingleColFeature, CallToAction } from "Sections/features";

import { RealTimeTracking, VisionConfig, SafetyConfig, CTAConfig,Serviceonfig } from "./config";

function Home() {
  const theme = useTheme();
  return (
    <>
      {/* <HeroSection id="TheHero" /> */}
      {/* <SingleColFeature heading={RealTimeTracking.head} description={RealTimeTracking.desc} img={RealTimeTracking.img} /> */}
      <SingleColFeature heading={Serviceonfig.head} description={Serviceonfig.desc} white />
      {/* <FeatureWithSteps
        heading={<>{SafetyConfig.head}</>}
        description={SafetyConfig.desc}
        steps={SafetyConfig.steps}
        zero
        i
      /> */}

      {/* <CallToAction heading={CTAConfig.head} description={CTAConfig.desc} /> */}
    </>
  );
}

export default Home;
