//images
import Features from "assets/images/hero/featurs.png";
import TransportForm from "assets/documents/transport-form-najmath.pdf";
import TransportAgreementForm from "assets/documents/nbt-transport-agreement.pdf";
import NotaryImage from "assets/images/illustrations/notary.svg";

const handleDownloadTraForm = () => {
  const link = document.createElement("a");
  link.download = "TransportForm";
  link.href = TransportForm;
  link.click();
};

const handleDownloadTraAgrForm = () => {
  const link = document.createElement("a");
  link.download = "TransportAgreementForm";
  link.href = TransportAgreementForm;
  link.click();
};

export const RealTimeTracking = {
  head: "Cash/Cheque Payments",
  desc: `Pay through cash / account
  payee cheque favouring
  'NAJMAT AL NAJAH SCHOOL BUS TRANSPORTATION LLC' at our fee counter at the school`,
};

export const VisionConfig = {
  head: `Payment by post dated cheques`,
  desc: "For your convenience, you may pay transport fees for whole year in lumpsum either in cash or by current dated cheque or bank transfer on or before due date of term 1 fees.",
  // img: NotaryImage,
};

export const ReportConfig = {
  head: `Reporting`,
  desc: "Reports for most common business requirements are delivered with GpsGate Server to help us analyze and improve your business. Customers can use the GpsGate Reporting framework to configure additional reports based on any data in the platform. Reports can be presented directly in the web browser or scheduled and sent over email.",
};

export const SafetyConfig = {
  head: `Explore our diverse payment methods tailored for your convenience`,
  // desc: `Our ethos revolves around customer success, unwavering safety, mutual support, and relentless pursuit of excellence. We prioritize accountability in decisions, setting the highest standards to drive success for all stakeholders.`,
  steps: [
    {
      head: `BANK TRANSFER`,
      desc: "Simply initiate transactions from your bank account to ours, ensuring swift and reliable payments without the need for physical visits.",
    },
    {
      head: "CASH/CHEQUE PAYMENT",
      desc: "Term 2",
    },
    {
      head: "15th November",
      desc: "Term 3",
    },
  ],
};

export const CTAConfig = {
  head: "With a clear vision for the safety and comfort of our clients. ",
  desc: "We maintain an impressive safety record due to our uncompromising dedication to safety in all our operations. Furthermore,we ensure that all new drivers undergo a pre-service training program, providing them with at least 30 hours of training before they commence their employment.",
};
