import React from "react";
import { useTheme } from "@mui/material/styles";
// sections
import { HeroSection } from "Sections/Services";
import { FeatureWithSteps, SingleColFeature, BulletList } from "Sections/features";

import {Fleetconfig,Satistconfig, Expertconfig} from "./config";

function Maintance() {
  const theme = useTheme();
  return (
    <>
      {/* <HeroSection id="TheHero" /> */}
      
      {/* <SingleColFeature heading={RealTimeTracking.head} description={RealTimeTracking.desc} img={RealTimeTracking.img} /> */}
      <FeatureWithSteps heading={Fleetconfig.head} description={Fleetconfig.desc} white imageUrl={Fleetconfig.imageUrl} changeContainer={false}/>
      <FeatureWithSteps heading={Satistconfig.head} description={Satistconfig.desc} white imageUrl={Satistconfig.imageUrl} imageOnLeft={false} changeContainer={false}/>
      <BulletList/>

      {/* <FeatureWithSteps
        heading={<>{SafetyConfig.head}</>}
        description={SafetyConfig.desc}
        steps={SafetyConfig.steps}
        zero
        i
      /> */}

      {/* <CallToAction heading={CTAConfig.head} description={CTAConfig.desc} /> */}
    </>
  );
}

export default Maintance;
