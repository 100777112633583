import { Stack } from "@mui/material";
import { styled } from "@mui/material/styles";

// image
import HeroImage from "assets/images/hero/payment-methods-left.svg";
import HeroImage2 from "assets/images/hero/payment-methods-right.svg";


export const RootStyle = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  minHeight: "800px",
  background: theme.palette.background.paper,
}));

export const ImageContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "800px",
  flex: 1,
  backgroundImage: `url(${HeroImage})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "100%",
  backgroundPosition: "bottom left",
  opacity: 0.6
}));

export const ImageContainer2 = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "800px",
  flex: 1,
  backgroundImage: `url(${HeroImage2})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "100%",
  backgroundPosition: "bottom",
  opacity: 0.6,
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

export const DataContainer = styled(Stack)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "absolute",
  left: "50%",
  transform: "translate(-50%)",
  top: 180,
  maxWidth: 750,
  width: "100%",
  zIndex: 5,
  [theme.breakpoints.down("md")]: {
    maxWidth: "100%",
    width: "100%",
    padding: theme.spacing(2)
  },
}));
