import { Stack } from "@mui/material";
import { styled } from "@mui/material/styles";

export const RootStyle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  minHeight: "600px",
  padding: theme.spacing(5, 0),
  position: "relative",
}));

export const DataContainer = styled(Stack)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  
}));

export const WhiteContainer = styled(Stack)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: theme.palette.background.paper,
  position: "absolute",
  height: 250,
  bottom: 0,
  left: 0,
  width: "100%",
  zIndex: -1
}));
