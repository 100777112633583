import React, { useEffect, useState } from "react";
// @mui
import { Grid, Typography, useTheme, Card, Paper, Container } from "@mui/material";
import { styled } from "@mui/material/styles";
import Iconify from "components/iconify";
import * as XLSX from "xlsx";

// styled components
import FeeStructureSrc from "assets/documents/Fee structure-DPS-SHARJAH-2023-24-Najmat.xlsx";
import useResponsive from "hooks/useResponsive";

const DefaultHeading = "This is the Heading";
const DefaultDescription = "";

const fetchData = async (setState) => {
  try {
    const response = await fetch(FeeStructureSrc);
    const blob = await response.blob();

    const reader = new FileReader();
    reader.onload = (event) => {
      const data = event.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[1]; // Assuming you want the first sheet
      const worksheet = workbook.Sheets[sheetName];
      const excelData = XLSX.utils.sheet_to_json(worksheet);
      if (excelData) {
        const FinalData = excelData.map((dataitem, index) => ({
          id: index + 1,
          icon: "lets-icons:calendar-fill",
          ...dataitem,
        }));
        setState(FinalData);
      }
    };
    reader.readAsBinaryString(blob);
  } catch (error) {
    console.error("Error occurred while reading the file:", error);
  }
};

const Step = ({ data, zero }) => {
  const theme = useTheme();
  return (
    <li style={{ display: "flex", marginRight: 20 }}>
      <Typography variant="body1" textAlign="center" mr={2}>
        {data.title }
      </Typography>
      {": "}
      <Typography variant="body1" textAlign="center" fontWeight="400" ml={2}>
        {data.date}
      </Typography>
    </li>
  );
};

function HeroSection({
  heading = DefaultHeading,
  description = DefaultDescription,
  steps = [],
  white = false,
  zero = false,
  headerWidth = "100%",
}) {
  const theme = useTheme();
  const mdDown = useResponsive("down", "md");
  const [Data, setData] = useState([]);

  useEffect(() => {
    if (!Data.length) {
      fetchData(setData);
    }
  }, []);

  return (
    <ul>
      {Data && (
        <>
          {Data.map((dataitem, index) => {
            return (
              <>
                <Step key={index} data={dataitem} zero={zero} />
              </>
            );
          })}
        </>
      )}
    </ul>
  );
}

export default HeroSection;
