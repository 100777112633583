import React, { useEffect, useState } from "react";
// @mui
import { Container, List, ListItem, Paper, Typography, useTheme } from "@mui/material";
import * as XLSX from "xlsx";
import { DataGrid } from "@mui/x-data-grid";

// styled components
import FeeStructureSrc from "assets/documents/Fee structure-DPS-SHARJAH-2023-24-Najmat.xlsx";
import useResponsive from "hooks/useResponsive";
import DueDates from "./dueDate";

const fetchData = async (setState) => {
  try {
    const response = await fetch(FeeStructureSrc);
    const blob = await response.blob();

    const reader = new FileReader();
    reader.onload = (event) => {
      const data = event.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[4]; // Assuming you want the first sheet
      const worksheet = workbook.Sheets[sheetName];
      const excelData = XLSX.utils.sheet_to_json(worksheet);
      if (excelData) {
        const FinalData = excelData.map((dataitem, index) => ({ id: index + 1, ...dataitem }));
        setState(FinalData);
      }
    };
    reader.readAsBinaryString(blob);
  } catch (error) {
    console.error("Error occurred while reading the file:", error);
  }
};

const CONFIG = {
  year: "2024-2025",
};

function FeeSection({ id, white = true }) {
  const theme = useTheme();
  const [ExcelData, setExcelData] = useState([]);
  const [TableHeaders, setTableHeaders] = useState([]);
  const mdDown = useResponsive("down", "md");

  useEffect(() => {
    if (!ExcelData.length) {
      fetchData(setExcelData);
    }

    if (ExcelData.length) {
      const keys = Object.keys(ExcelData[1]); // Extract keys from the first row of Excel data
      const filteredKeys = keys.filter((key) => key !== "id"); // Remove the 'id' key
      const finalData = filteredKeys.map((key) => ({
        headerName: key.toString().toLowerCase(),
        field: key,
        flex: key.includes("description") ? 4 : 1,
        display: "flex",
        flexWrap: "wrap",
        whiteSpace: "break-spaces",
        minWidth: key.length > 15 ? 220 : 80,
        sortable: false,
      }));
      setTableHeaders(finalData);
    }
  }, [ExcelData]);

  return (
    <div style={{ background: white && theme.palette.background.paper }}>
      <Container maxWidth="xl" sx={{ py: 10 }}>
        <Typography
          variant={mdDown ? "h3" : "h1"}
          textAlign="center"
          fontWeight="700"
          fontFamily='"Merriweather", serif'
        >
          Transport fees forthe academic year {CONFIG.year}, DPS RAK
        </Typography>
        <Typography variant="body1" textAlign="center" mt={2} mx={4} px={3}>
          NAJMAT AL NAJAH SCHOOL BUS TRANSPORTATION LLC <strong>(NBT) </strong> is managed by
          experienced professionals who have a long track record of safe transportation to students.
        </Typography>

        {TableHeaders && (
          <Paper
            sx={{
              mt: 5,
              "& .MuiDataGrid-cellContent": {
                whiteSpace: "break-spaces",
                padding: "8px 0px",
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                textTransform: "uppercase",
                fontSize: 12,
              },
            }}
          >
            <DataGrid
              rowSelection={false}
              rows={ExcelData}
              getRowHeight={() => "auto"}
              columns={TableHeaders}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 10 },
                },
              }}
              disableColumnMenu
              hideFooterPagination={true}
            />
          </Paper>
        )}

        <div
          style={{
            marginTop: 16,
          }}
        >
          <Typography textTransform="uppercase" sx={{ fontWeight: "bold" }} mt={3} mb={1}>
            Payment Due Dates For New Admissions :
          </Typography>
          <Typography variant="body1">Immediately upon admission</Typography>
        </div>

        <div
          style={{
            marginTop: 16,
          }}
        >
          <Typography textTransform="uppercase" sx={{ fontWeight: "bold" }} mt={3} mb={1}>
            Payment Due Dates For Regular Students{" "}
          </Typography>
          <div style={{ display: "flex", width: "100%" }}>
            <DueDates />
          </div>
        </div>

        <List sx={{ mt: 2 }}>
          <Typography>NOTE</Typography>
          <ListItem>
            ⚫ Student will not be allowed to board the bus in case payment is not received before
            last date of payment (as mentioned above)
          </ListItem>
          <ListItem>
            ⚫ Vat or any other taxes if applicable on transport fee shall be charged additionally
            on above amounts.
          </ListItem>
          <ListItem>
            ⚫ Once the RFID card has been issued and the bus/seat allocation has been done, the fee
            is payable, irrespective of the usage or non-usage of the bus service.
          </ListItem>
        </List>
      </Container>
    </div>
  );
}

export default FeeSection;
