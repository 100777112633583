import PropTypes from 'prop-types';
import { memo } from 'react';

// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField, MenuItem, InputAdornment, CircularProgress } from '@mui/material';

// ----------------------------------------------------------------------

RHFTextField.propTypes = {
  name: PropTypes.string,
};

function RHFTextField({
  name,
  select,
  options,
  valueKey,
  LabelKey,
  compoffdate,
  loading,
  SelectProps,
  ...other
}) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <TextField
            {...field}
            select={select}
            fullWidth
            value={field.value}
            onBlur={()=>!!error}
            error={!!error}
            helperText={error?.message}
            InputProps={{
              disableUnderline: true,
              endAdornment: loading && (
                <InputAdornment position="start" sx={{ marginRight: 2 }}>
                  <CircularProgress size={20} />
                </InputAdornment>
              ),
            }}
            {...other}
            SelectProps={{
              MenuProps: {
                disableScrollLock: true,
              },
              ...SelectProps,
            }}
          >
            {options &&
              options.map((option) => (
                <MenuItem
                  key={valueKey ? option[valueKey] : option.value}
                  value={valueKey ? option[valueKey] : option.value}
                >
                  {LabelKey ? option[LabelKey] : option.label}
                </MenuItem>
              ))}
          </TextField>
        );
      }}
    />
  );
}

export default memo(RHFTextField);
