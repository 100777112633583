import React from "react";
import { useTheme } from "@mui/material/styles";
// sections
import { HeroSection, Feesection, DueDate, FeesectionRAK } from "Sections/Fees";
import { FeatureWithSteps, SingleColFeature, CallToAction } from "Sections/features";

import { RealTimeTracking, VisionConfig, SafetyConfig, CTAConfig } from "./config";

function Home() {
  const theme = useTheme();



  return (
    <>
      {/* <HeroSection id="TheHero" /> */}
      {/* <SingleColFeature
        heading={RealTimeTracking.head}
        description={RealTimeTracking.desc}
        img={RealTimeTracking.img}
        action={RealTimeTracking?.action}
      />
      <SingleColFeature
        heading={VisionConfig.head}
        description={VisionConfig.desc}
        white
        img={VisionConfig?.img}
      /> */}
      <Feesection id="fees-section" white={false} />
      <FeesectionRAK id="fees-section-rak" />

      {/* <DueDate
        heading={<>{SafetyConfig.head}</>}
        description={SafetyConfig.desc}
        zero
      /> */}

      {/* <CallToAction heading={CTAConfig.head} description={CTAConfig.desc} /> */}
    </>
  );
}

export default Home;
