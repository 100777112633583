import React from "react";
import { useTheme } from "@mui/material/styles";
// sections
import { HeroSection, Contacts, MapSection } from "Sections/ContactUs";
import { FeatureWithSteps, SingleColFeature, CallToAction } from "Sections/features";

import { RealTimeTracking, VisionConfig, SafetyConfig, CTAConfig } from "./config";
import ContactForm from "./../../Sections/ContactUs/ContactForm";

function Home() {
  const theme = useTheme();
  return (
    <>
      {/* <HeroSection id="TheHero" /> */}
      <ContactForm />
      {/* <div style={{ marginTop: "-124px", zIndex: 5 }}> */}
      <Contacts />
      {/* </div> */}
      <MapSection />
      {/* <CallToAction heading={CTAConfig.head} description={CTAConfig.desc} /> */}
    </>
  );
}

export default Home;

// Bhupendra sati
// Senior Section Transport officer / najmat / 


// Mr. Shahen Mohammed
// Tranport supervisor / Al saraya
