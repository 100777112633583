import React, { useEffect, useState } from "react";
import SpaceFill from "components/spacefill";

import { RootStyle, ToolbarStyle, LogoContainer } from "./styles";

// logo image
import Logo from "assets/images/logos/logo.png";
import { useTheme } from "@mui/material/styles";
import { Slide, Button, IconButton } from "@mui/material";

import NavSection from "./navsection";
import { Link } from "react-router-dom";
import ContactBtn from "components/ContactBtn";
import useResponsive from "hooks/useResponsive";
import Iconify from "components/iconify/Iconify";

function navbar({ handleDrawerOpen }) {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [shrinkNavbar, setShrinkNavbar] = useState(false);
  const [showBtn, setShowBtn] = useState(false);
  const theme = useTheme();

  const lgDown = useResponsive("down", "lg");

  useEffect(() => {
    const handleScroll = () => {
      const position = window.scrollY;
      setScrollPosition(position);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (scrollPosition > 64) {
      setShrinkNavbar(true);
    } else {
      setShrinkNavbar(false);
    }
  }, [scrollPosition]);

  useEffect(() => {
    if (scrollPosition > 445) {
      setShowBtn(true);
    } else {
      setShowBtn(false);
    }
  }, [scrollPosition]);

  return (
    <RootStyle
      sx={{
        borderBottom: shrinkNavbar ? `1px solid ${theme.palette.divider}` : "1px solid transparent",
      }}
    >
      <ToolbarStyle sx={{ maxHeight: 100, alignItems: "center" }}>
        <LogoContainer>
          <Link to="/">
            <img src={Logo} alt="Logo" style={{ width: "100%", objectFit: "contain" }} />
          </Link>
        </LogoContainer>

        <SpaceFill sx={{ justifyContent: "flex-end" }}>
          {lgDown ? (
            <div style={{ marginRight: 32 }}>
              <IconButton onClick={handleDrawerOpen}>
                <Iconify icon="nimbus:menu" width={30} />
              </IconButton>
            </div>
          ) : (
            <NavSection />
          )}
          {!lgDown && (
            <div style={{display: "flex", alignItems: "center"}}>
              <ContactBtn nav />
            </div>
          )}
        </SpaceFill>
      </ToolbarStyle>
    </RootStyle>
  );
}

export default navbar;
