import { Stack, Typography, Box, Divider } from '@mui/material';
import { useTheme, styled } from '@mui/material/styles';
import { bgBlur } from '../../utils/cssStyles'

// ------------------------styled components-----------------------------------
const StyledHead = styled(Box)(({ theme }) => {
  return {
    ...bgBlur({ color: theme.palette.background.paper }),
    boxShadow: 'none',
    paddingBottom: theme.spacing(3),
  };
});
// ------------------------styled components-----------------------------------

export default function StyledHeaders(props) {
  const { title, disableBreadcrumbs, children, sx } = props;
  return (
    <StyledHead component="div" px={3} sx={sx}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <div>
          <Typography variant="h4" gutterBottom sx={{ fontWeight: 600 }} p={3}>
            {title}
          </Typography>
          {/* {title && !disableBreadcrumbs && <Breadcrumbs />} */}
        </div>

        {children}
      </Stack>
    </StyledHead>
  );
}
