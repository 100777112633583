import React from "react";
import { useTheme } from "@mui/material/styles";
// sections
import { FilterSection, Listing } from "Sections/SearchRoutes";

import { useForm } from "react-hook-form";
import { FormProvider } from "components/hook-form";

import { WhyNBTConfig } from "./config";

function Home() {
  const theme = useTheme();

  // form
  const defaultValues = {
    division: "0",
    areacode: "0",
    routecode: "0",
  };

  const methods = useForm({
    defaultValues,
  });

  const { handleSubmit } = methods;
  // form

  const onSubmit = async () => {
    setLoading(true);
    setTimeout(() => {
      navigate("/services/bus-routes/search");
      setLoading(false);
    }, 800);
  };



  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <FilterSection id="TheHero" />
      <div style={{ height: 60 }} />
      <Listing heading={WhyNBTConfig.head} description={WhyNBTConfig.desc} />
    </FormProvider>
  );
}

export default Home;
