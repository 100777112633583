import React from "react";
// @mui
import { Stack, Typography, useTheme } from "@mui/material";

// styled components
import { ImageContainer, ImageContainer2, RootStyle, DataContainer } from "./styles";
import useResponsive from "hooks/useResponsive";

function HeroSection({ id }) {
  const theme = useTheme();
  const mdDown = useResponsive("down", "md");

  return (
    <RootStyle id={id}>
      <DataContainer>
        <Typography
          variant={mdDown ? "h3" : "h1"}
          textAlign="center"
          fontWeight="700"
          fontFamily='"Merriweather", serif'
        >
          Get in Touch
        </Typography>
        <Typography variant="body1" textAlign="center" mt={2} mx={{ xs: 1, md: 8 }}>
          Our contact details are as given below. Feel free to reach out to us via the provided
          email, or phone number. We're here to assist you and look forward to connecting with you
          soon
        </Typography>
      </DataContainer>
      <ImageContainer />
    </RootStyle>
  );
}

export default HeroSection;
