import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./styles.css";
import { SliderImage } from "./styles";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";

export default function App() {
  return (
    <>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        loop
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/najmat-fcf50.appspot.com/o/images%2F1.jpg?alt=media&token=83104b9d-ccb4-4406-b6a0-0c8e5096cf2b"
            alt="First Slide"
            style={{ height: 600, width: "100%" }}
          />
        </SwiperSlide>
        {/* <SwiperSlide style={{ height: 600, width: "100%" }}>
          <img src="https://firebasestorage.googleapis.com/v0/b/najmat-fcf50.appspot.com/o/images%2F3.jpg?alt=media&token=9da8a9c1-6a3a-458c-9b8b-c912d4b2a329" />
        </SwiperSlide> */}
        <SwiperSlide style={{ height: 600, width: "100%" }}>
        <img src="https://firebasestorage.googleapis.com/v0/b/najmat-fcf50.appspot.com/o/images%2F4.jpg?alt=media&token=fe2f4899-7631-4625-bf59-69c153ae4624" />
        </SwiperSlide>
        <SwiperSlide style={{ height: 600, width: "100%" }}>
        <img src="https://firebasestorage.googleapis.com/v0/b/najmat-fcf50.appspot.com/o/images%2F5.jpg?alt=media&token=5caf0fdd-4520-4ab7-b204-0868358ce0b5" />
        </SwiperSlide>
        <SwiperSlide style={{ height: 600, width: "100%" }}>
        <img src="https://firebasestorage.googleapis.com/v0/b/najmat-fcf50.appspot.com/o/images%2F6.jpg?alt=media&token=75df274c-91ce-47d8-b199-07f7f8cc5c15" />
        </SwiperSlide>
        <SwiperSlide style={{ height: 600, width: "100%" }}>
        <img src="https://firebasestorage.googleapis.com/v0/b/alsaraya-d8bd7.appspot.com/o/images%2Fimage8.jpg?alt=media&token=01b80229-52a6-40b0-89d9-090eaf633b51" />
        </SwiperSlide>
        <SwiperSlide style={{ height: 600, width: "100%" }}>
        <img src="https://firebasestorage.googleapis.com/v0/b/alsaraya-d8bd7.appspot.com/o/images%2Fimage9.jpg?alt=media&token=6865b8b2-b5e1-4d80-9ba5-2e9d2aea62ed" />
        </SwiperSlide>
        {/* <SwiperSlide style={{ height: 600, width: "100%" }}>
          <SliderImage />
        </SwiperSlide>
        <SwiperSlide style={{ height: 600, width: "100%" }}>
          <SliderImage />
        </SwiperSlide>
        <SwiperSlide style={{ height: 600, width: "100%" }}>
          <SliderImage />
        </SwiperSlide> */}
      </Swiper>
    </>
  );
}
