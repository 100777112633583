import React from "react";
// @mui
import { Stack, Typography, useTheme } from "@mui/material";

// styled components
import { ImageContainer, ImageContainer2, RootStyle, DataContainer } from "./styles";
import QRImage from "assets/documents/skiply-qr-code.svg";

function HeroSection({ id }) {
  const theme = useTheme();

  return (
    <RootStyle id={id}>
      <DataContainer>
        <Typography
          variant="h1"
          textAlign="center"
          fontWeight="700"
          fontFamily='"Merriweather", serif'
        >
          Assured Transactions
          <br />{" "}
          <Typography variant="h3" fontFamily='"Merriweather", serif'>
            Your Trust, Our Commitment
          </Typography>
        </Typography>
        <Typography variant="body1" textAlign="center" mt={2} mx={4} px={3}>
          Kindly scan the QR code provided below to download <b>Skiply App</b>.
        </Typography>
        <img src={QRImage} style={{ height: 320, width: 320, marginTop: 48 }} />
      </DataContainer>
      <ImageContainer />
      <ImageContainer2 />
    </RootStyle>
  );
}

export default HeroSection;
