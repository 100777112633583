import React from "react";
 import { FeesFAQs } from "./config";
import DropList from "../../Sections/FAQ/DropList";
import { Stack, Typography } from "@mui/material";
import useResponsive from "hooks/useResponsive";

const Index = () => {
  const mdDown = useResponsive("down", "md");

  return (
    <Stack sx={{ margin: "50px 0", padding: "0 40px" }}>
      <Typography
        variant={mdDown ? "h3" : "h1"}
        textAlign="center"
        fontWeight="700"
        fontFamily='"Merriweather", serif'
        gutterBottom
        my={3}
      >
        Frequently Asked Questions
      </Typography>
      <DropList items={FeesFAQs} />
    </Stack>
  );
};

export default Index;
