import React from "react";
import { useTheme } from "@mui/material/styles";
// sections
import { FeesectionRAK, DueDate } from "Sections/Fees";
import { Feesection as FSection, DueDate as DSection } from "Sections/PaymentModes";

import { FeatureWithSteps, SingleColFeature, CallToAction } from "Sections/features";

import { RealTimeTracking, VisionConfig, SafetyConfig, CTAConfig } from "./config";

function Home() {
  const theme = useTheme();

  return (
    <>
      <FeesectionRAK id="fees-section" white={false} />
      <FSection heading="Payment Modes"/>

      {/* <CallToAction heading={CTAConfig.head} description={CTAConfig.desc} /> */}
    </>
  );
}

export default Home;
