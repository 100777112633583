export const FeesFAQs = [
  {
    label: "What are the due dates for the transport fees payment?",
    description:
      "Kindly visit our website (www.nbt.ae) for more information about fees & payment modes.",
  },
  {
    label: "What is the mode of transport fee payment?",
    description:
      "Payment can be made through the following way:\n1. SKIPLY app (Download the SKIPLY app in your Google Play or App Store)\n2. Bank Transfer\n3. Cheque\n4. Cash at the fee counter\n5. Credit or debit cards at the fee counter",
  },
  {
    label: "Is any VAT applicable on transport fees?",
    description:
      "Currently, VAT is not applicable. However, in the future VAT or any other taxes if applicable on the transport fees shall be charged additionally, accordingly.",
  },
  {
    label: "What is the policy on transport cancellation?",
    description:
      "You are required to fill in the transport cancellation form with 3 (three) month’s prior notice (with payment of fee) to the NBT Transport desk, if you want to discontinue the usage of school transport at any time during the academic year (April - March).\nIf the bus usage is discontinued without written notice, then it will be deemed that the student continues to use the bus services, irrespective of actual usage. Pls, visit www.nbt.ae for more details.",
  },
  {
    label: "Is part/monthly payment option available?",
    description: "No, the transport fee payment can only be made on a Term basis.",
  },
  {
    label: "Can I send the transport fees through the bus driver?",
    description:
      "It is strictly forbidden for our driver to carry the transport fees (Cheque or cash) of/ from the parents.",
  },

]
export const LogisticsFAQs = [
  {
    label: "Is there any application to track the bus?",
    description:
      "Yes, the School bus tracking application is available. The school ICT dept will provide the access details.",
  },
  {
    label: "Will there be any notification while student board and deboard the bus?",
    description:
      "No, currently we do not have the facility. However, through the RFID scan mismatch reports, we can identify the students using the wrong bus de-boarded at the wrong stop.",
  },
  {
    label: "Is it possible to drop my child at another stop?",
    description:
      "No, pickup and drop will be available from the same point as provided by the transport office. However, if you want us to drop you at a different stop from the registered one, you will need to visit the Transport Department and submit a stop change form.",
  },
  {
    label: "Can we have the CCTV recording of our students’ bus?",
    description:
      "CCTV recordings will be provided only to school management. The parents need to contact the school for the same.",
  },
  {
    label: "Complaint against the driver for not waiting at the stop.",
    description:
      "All the students have provided a pickup time, and he/she should be available at the stop at least 5 minutes before the provided time. Drivers are not allowed to wait for any staff or students.",
  },
  {
    label: "Where can I see the bus number & the driver’s details?",
    description: "Kindly visit www.nbt.ae and check the “Services” drop-down list.",
  },
  {
    label: "What is the pickup/drop off timings of my ward?",
    description:
      "The approximate pickup & drop off timings are mentioned on the route chart. Pls, refer www.nbt.ae  The exact timings of the stop can be communicated with the driver.",
  },
  {
    label: "Can we board different buses in the morning & the afternoon?",
    description:
      "No, the transport service will be provided on the same bus and the same pickup/drop off point at both times, during morning & afternoon. Any deviation by the student will be detected by the RFID device in our buses.",
  },
  {
    label: "Can we get one-way service?",
    description:
      "There is no provision for one-way service. However, the parent may pick/drop their ward in the morning or the afternoon trip, informing the bus driver. During the parent's pick up at the afternoon dispersal, the parent must get the “Gate out pass” from the school administration office.",
  },
  {
    label: "Is there any Camera available in the bus?",
    description: "Yes, there are CCTV–IP cameras installed inside and outside the buses.",
  },
  {
    label: "Will there be any seat assigned to the student?",
    description: "Yes, there will be a seat assigned for each student on the bus.",
  },
  {
    label: "Are there seat belts available on the buses?",
    description: "Yes, all the students should compulsorily always fasten their seat belts.",
  },
  {
    label: "Is there any attendant available on the bus?",
    description: "Yes, during the journey, there is always a lady conductor/helper on the bus.",
  },
  {
    label: "Can we track the buses on GPS?",
    description:
      "Yes, the School bus tracking application is available. The school ICT dept will provide the access details.",
  },
  {
    label: "Can the diversion of the bus be possible to nearby areas?",
    description:
      "The diversion of the bus depends on the factor of time/distance/no. of students in any area.",
  },
  {
    label: "What is the procedure for route change?",
    description:
      "Firstly, you must check the new location’s availability by going through our routes which are available on our website. Once confirmed then you may download the transport form and send it to the primary or senior section duly filled along with a payment screenshot of AED 50/- towards administration charges for route change. In case, there is an area change, you may write to the transport fees department at fees@najmat.ae, who will then assist you with the exact amount to be transferred. A new RFID card shall be issued to your ward once all the formalities is completed. Students are strictly not allowed to change the route without prior notice.",
  },
  {
    label: "How safe is a Pre-kg child on the bus?",
    description:
      "We have trained drivers who have expertise in efficient driving, especially for kindergarten students. Additionally, nannies are present in all the school buses to assist them while seating and deboarding, continuously monitors the activities in the bus throughout the trip. Most importantly, all the Pre-Kg kids are provided with an individual booster seat having 3-point seatbelt, in all the buses to ensure complete safety of the student and a comfortable ride on the bus. Rest assured; necessary arrangements have been made on the bus to ensure your child is safe in the bus.",
  },
  {
    label: "Is there any sibling discount?",
    description: "Yes, a sibling discount applies to the 4th child only.",
  },
];
