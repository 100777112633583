import React, { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment/moment';
import { Button, Typography, Box, Grid } from '@mui/material';
import { RHFCheckbox, RHFDatepicker, RHFTextField } from '../../components/hook-form';

// Define your form schema
const Schema = Yup.object().shape({
    schoolAcademicYear: Yup.string().required('School Academic Year is required'),
    admissionNo: Yup.string().required('Admission No is required'),
    date: Yup.date().nullable().required('Date is required'),
    studentName: Yup.string().required('Name of the student is required'),
    grade: Yup.string().required('Grade is required'),
    division: Yup.string().required('Division is required'),
    confirmedDateOfEntry: Yup.date().nullable().required('Confirmed Date of Entry is required'),
    emirates: Yup.string().required('Emirates is required'),
    pickupDropoffPoint: Yup.string().required('Pick-up / Drop-off Point is required'),
    address: Yup.string().required('Address is required'),
    oldBusRouteNo: Yup.string().required('Old Bus Route No. is required'),
    newBusRouteNo: Yup.string().required('New Bus Route No. is required'),
    oldBusStop: Yup.string().required('Old Bus Stop is required'),
    newBusStop: Yup.string().required('New Bus Stop is required'),
    oldLocation: Yup.string().required('Old Location is required'),
    newLocation: Yup.string().required('New Location is required'),
    busRouteNo: Yup.string().required('Bus Route No. is required'),
    busRouteName: Yup.string().required('Bus Route Name (Area) is required'),
    noticeDate: Yup.date().nullable().required('Notice Date is required'),
    cancellationDate: Yup.date().nullable().required('Cancellation Date is required'),
    reasonForCancellation: Yup.string().required('Reason for cancellation is required'),
});

const TransportFormParent = () => {
    const methods = useForm({
        resolver: yupResolver(Schema),
        defaultValues: {
            // Set default values here
        }
    });

    const { handleSubmit, formState: { errors } } = methods;

    const onSubmit = (data) => {
        console.log(JSON.stringify(data));
    };

    return (
        <Box sx={{ width: '100%' }} mt={5}>
            <div>
          <Typography variant="h3" gutterBottom sx={{ fontWeight: 600 }} pb={3}>
            Transport Form for a parents
          </Typography>
        </div>
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <div>
                                <Typography variant="subtitle2" color="text.secondary">
                                    School Academic Year
                                </Typography>
                                <RHFTextField name="schoolAcademicYear" />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <div>
                                <Typography variant="subtitle2" color="text.secondary">
                                    Admission No
                                </Typography>
                                <RHFTextField name="admissionNo" />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <div>
                                <Typography variant="subtitle2" color="text.secondary">
                                    Date
                                </Typography>
                                <RHFDatepicker name="date" />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <div>
                                <Typography variant="subtitle2" color="text.secondary">
                                    Name of the student
                                </Typography>
                                <RHFTextField name="studentName" />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <div>
                                <Typography variant="subtitle2" color="text.secondary">
                                    Grade
                                </Typography>
                                <RHFTextField name="grade" />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <div>
                                <Typography variant="subtitle2" color="text.secondary">
                                    Division
                                </Typography>
                                <RHFTextField name="division" />
                            </div>
                        </Grid>
                        <Typography variant="h6" gutterBottom sx={{ fontWeight: 300, color: 'gray', fontStyle: 'italic' }} ml={3}>
            Note: Only limited areas are covered, kindly visit Najmat website for more information - <a href="https://www.nbt.ae" style={{ textDecoration: 'none', color: 'inherit' }}>www.nbt.ae</a>
        </Typography>
                        <Typography variant="h5" gutterBottom sx={{ fontWeight: 300, backgroundColor: '#344767', width: '100%', color: 'white !important' }}p={0.5} my={5} ml={3}>
                Please provide the exact location for new transport: (NEW TRANSPORT REQUEST)
          </Typography>
                        <Grid item xs={12} md={6}>
                            <div>
                                <Typography variant="subtitle2" color="text.secondary">
                                    Confirmed Date of Entry
                                </Typography>
                                <RHFDatepicker name="confirmedDateOfEntry" />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <div>
                                <Typography variant="subtitle2" color="text.secondary">
                                    Emirates
                                </Typography>
                                <RHFTextField name="emirates" />
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div>
                                <Typography variant="subtitle2" color="text.secondary">
                                    Pick-up / Drop-off Point
                                </Typography>
                                <RHFTextField name="pickupDropoffPoint" />
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div>
                                <Typography variant="subtitle2" color="text.secondary">
                                    Address
                                </Typography>
                                <RHFTextField name="address" />
                            </div>
                        </Grid>
                        <Typography variant="h6" gutterBottom sx={{ fontWeight: 300, color: 'gray', fontStyle: 'italic' }} ml={3}>
                        Note:- One month prior notice required for any route change and administrative charge AED 100/- to be paid (Subject to seat and bus availability)
        </Typography>
                        <Typography variant="h5" gutterBottom sx={{ fontWeight: 300, backgroundColor: '#344767', width: '100%', color: 'white !important' }}p={0.5} my={5} ml={3}>
                        Please provide the details if change of residence and bus routes: (BUS OR STOP CHANGE REQUEST)
          </Typography>
                        <Grid item xs={12} md={6}>
                            <div>
                                <Typography variant="subtitle2" color="text.secondary">
                                    Old Bus Route No.
                                </Typography>
                                <RHFTextField name="oldBusRouteNo" />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <div>
                                <Typography variant="subtitle2" color="text.secondary">
                                    New Bus Route No.
                                </Typography>
                                <RHFTextField name="newBusRouteNo" />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <div>
                                <Typography variant="subtitle2" color="text.secondary">
                                    Old Bus Stop
                                </Typography>
                                <RHFTextField name="oldBusStop" />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <div>
                                <Typography variant="subtitle2" color="text.secondary">
                                    New Bus Stop
                                </Typography>
                                <RHFTextField name="newBusStop" />
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div>
                                <Typography variant="subtitle2" color="text.secondary">
                                    Old Location
                                </Typography>
                                <RHFTextField name="oldLocation" />
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div>
                                <Typography variant="subtitle2" color="text.secondary">
                                    New Location
                                </Typography>
                                <RHFTextField name="newLocation" />
                            </div>
                        </Grid>
                        <Typography variant="h6" gutterBottom sx={{ fontWeight: 300, color: 'gray', fontStyle: 'italic' }} ml={3}>
                        Note:- Request for discontinuation of transport facility must be submitted THREE MONTHS in advance to the Najmat office. If the bus usages are discontinued 
without written notice, then it will be deemed that the student continues to use the bus services, irrespective of actual usage. This also applies to the fee defaulter of 
that particular term.

        </Typography>
        <Typography variant="h5" gutterBottom sx={{ fontWeight: 300, backgroundColor: '#344767', width: '100%', color: 'white !important' }}p={0.5} my={5} ml={3}>
            Please fill the details for transport cancellation: (TRANSPORT CANCELLATION REQUEST)
        </Typography>
                        <Grid item xs={12} md={6}>
                            <div>
                                <Typography variant="subtitle2" color="text.secondary">
                                    Bus Route No.
                                </Typography>
                                <RHFTextField name="busRouteNo" />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <div>
                                <Typography variant="subtitle2" color="text.secondary">
                                    Bus Route Name (Area)
                                </Typography>
                                <RHFTextField name="busRouteName" />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <div>
                                <Typography variant="subtitle2" color="text.secondary">
                                    Notice Date
                                </Typography>
                                <RHFDatepicker name="noticeDate" />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <div>
                                <Typography variant="subtitle2" color="text.secondary">
                                    Cancellation with effect from
                                </Typography>
                                <RHFDatepicker name="cancellationDate" />
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div>
                                <Typography variant="subtitle2" color="text.secondary">
                                    Reason for cancellation
                                </Typography>
                                <RHFTextField name="reasonForCancellation" />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={12} mt={2}>
          <div>
          <RHFCheckbox clearable name="agreed" label={
                <>
                    I have read and understood the{' '}
                    <a href="https://firebasestorage.googleapis.com/v0/b/najmat-49083.appspot.com/o/pdf%2Fnbt-transport-agreement.pdf?alt=media&token=7452bcdd-9ea8-43c2-847c-a1f66175c4fa" target="_blank" rel="noopener noreferrer">
                        Terms & Conditions
                    </a>{' '}
                    related to the school bus logistics and confirm my acceptance
                </>
            } />
          </div>
        </Grid>
                    <div style={{ marginTop: "50px", display: "flex", justifyContent: "flex-end", marginBottom:"20px"}}>
                        <Button variant="contained" color="primary" type="submit">
                            Submit
                        </Button>
                    </div>
                </form >
            </FormProvider>
        </Box>
    );
}

export default TransportFormParent;
