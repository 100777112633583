// @mui
import { Divider, Container, Typography, Grid, Card } from "@mui/material";
import { RHFTextField } from "components/hook-form";

import { RootStyle, BusItemStyle } from "./styles";

import { useFormContext } from "react-hook-form";

// mock Data
import { BusRouteData, getBusesOnRoute } from "_mock/BusData";

// const FilterData =

const RenderBuses = ({ data }) => {
  return (
    <BusItemStyle>
      <Typography variant="body2" color="text.secondary">
        {data.division}
      </Typography>
      <Divider />

      <Typography variant="h5" color="text.secondary">
        Bus No. {data.busNumber}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        {data.route}
      </Typography>
    </BusItemStyle>
  );
};

export default function RouteListing({ data }) {
  const methods = useFormContext();
  const { watch } = methods;

  return (
    <RootStyle>
      <Container>
        <Grid container spacing={2} display="flex" flex={1}>
          {getBusesOnRoute(
            BusRouteData,
            watch("division"),
            watch("areacode"),
            watch("routecode")
          ).map((busdata) => (
            <Grid item xs={12} md={6} lg={3} display="flex" flex={1}>
              <RenderBuses data={busdata} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </RootStyle>
  );
}
