import React from "react";
// @mui
import { Grid, Typography, useTheme, Card, Paper, Container } from "@mui/material";
import { styled } from "@mui/material/styles";
import Iconify from "components/iconify";

// styled components
import { ImageContainer, ImageContainer2, RootStyle, DataContainer } from "./styles";
import useResponsive from "hooks/useResponsive";

const DefaultHeading = "This is the Heading";
const DefaultDescription = "";

const Step = ({ data, zero }) => {
  const theme = useTheme();

  return (
    <Card
      sx={{
        minHeight: "70%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        flex: 1,
        p: 3,
        alignItems: "center",
        borderRadius: 3,
        boxShadow: zero && "none",
      }}
    >
      {data.icon && (
        <div
          style={{
            width: 80,
            height: 80,
            background: theme.palette.background.default,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 100,
            marginBottom: 16,
          }}
        >
          <Iconify icon={data.icon} width={40} color={theme.palette.primary.main} />
        </div>
      )}
      <Typography variant="h4" textAlign="center">
        {data.head}
      </Typography>
      <Typography variant="body2" textAlign="center" mt={2} fontWeight="400">
        {data.desc}
      </Typography>
    </Card>
  );
};

function HeroSection({
  heading = DefaultHeading,
  description = DefaultDescription,
  steps = [],
  white = false,
  zero = false,
  imageUrl = "",
  headerWidth = "100%",
  imageOnLeft = true,
  changeContainer = true
}) {
  const theme = useTheme();
  const mdDown = useResponsive("down", "md");

  const Wrapper = changeContainer ? Container : "div";

  return (
    <div style={{ background: white && theme.palette.background.paper }}>
      {imageUrl ? (<Wrapper maxWidth="sm" sx={{ py: imageUrl ? 0 : 10  }}>

        <Grid container spacing={5} alignItems="center">
          {/* Conditionally render Image based on the prop */}
          {imageOnLeft ? (
            <Grid item xs={12} md={6} lg={6}>
              <img src={imageUrl} alt="Your Alt Text" style={{ width: '100%', height: 'auto' }} />
            </Grid>
          ) : null}
  
          {/* Conditionally render Heading and Description based on the prop */}
          <Grid item xs={12} md={6} lg={6}>
            <div style={{ width: "100%", maxWidth: 800, margin: "0 auto" }}>
              <Typography variant={mdDown ? "h3" : "h1"} textAlign="center" fontWeight="700">
                {heading}
              </Typography>
              <Typography variant="body1" textAlign="center" mt={2} color="text.secondary" px={6}>
                {description}
              </Typography>
              
  
              {steps && (
                <Grid
                  container
                  mt={5}
                  flex={1}
                  justifyContent="center"
                  spacing={3}
                  sx={{
                    px: 0,
                    visibility: "hidden",
                    "& > *": {
                      visibility: "visible",
                      transition: "opacity 150ms linear 100ms, transform 150ms ease-in-out 100ms",
                    },
  
                    "&:hover > *": {
                      opacity: 0.4,
                    },
                    "& > *:hover": {
                      opacity: 1,
                      transform: "scale(1.06)",
                    },
                  }}
                >
                  {steps.map((dataitem, index) => (
                    <Grid key={index} item xs={12} md={6} lg={3}>
                      <Step data={dataitem} zero={zero} />
                    </Grid>
                  ))}
                </Grid>
              )}
            </div>
          </Grid>

          {/* Conditionally render Image based on the prop */}
          {imageOnLeft ? null : (
            <Grid item xs={12} md={6} lg={6}>
              <img src={imageUrl} alt="Your Alt Text" style={{ width: '100%', height: 'auto' }} />
            </Grid>
          )}
        </Grid>
      </Wrapper>) : <Container maxWidth="sm" sx={{ py: 10 }}>
        <div style={{ width: "100%", height: "100%", width: headerWidth, margin: "0 auto" }}>
          <Typography variant={mdDown ? "h3" : "h1"} textAlign="center" fontWeight="700">
            {heading}
          </Typography>
        </div>
        <div style={{ maxWidth: 800, margin: "0 auto" }}>
          <Typography variant="body1" textAlign="center" mt={2} color="text.secondary">
            {description}
          </Typography>
        </div>

        {steps && (
          <Grid
            container
            mt={5}
            flex={1}
            justifyContent="center"
            spacing={3}
            sx={{
              px: 0,
              visibility: "hidden",
              "& > *": {
                visibility: "visible",
                transition: "opacity 150ms linear 100ms, transform 150ms ease-in-out 100ms",
              },

              "&:hover > *": {
                opacity: 0.4,
              },
              "& > *:hover": {
                opacity: 1,
                transform: "scale(1.06)",
              },

              // "& .MuiGrid-item:hover ~ .MuiGrid-item": {
              //   opacity: 0.6,
              // },
            }}
          >
            {steps.map((dataitem, index) => {
              return (
                <Grid key={index} item xs={12} md={6} lg={3}>
                  <Step  data={dataitem} zero={zero} />
                </Grid>
              );
            })}
          </Grid>
        )}
      </Container>}
    </div>
  );
  
}

export default HeroSection;
