import React from "react";
import { useNavigate } from "react-router-dom";

// @mui
import { Button, Typography, useTheme } from "@mui/material";

// styled components
import { ImageContainer, ImageContainer2, RootStyle, DataContainer } from "./styles";

// components
import RoutesBtn from "components/RoutesBtn";
import ContactBtn from "components/ContactBtn";
import useResponsive from "hooks/useResponsive";

import HeroSlider from "./HeroSlider";

function HeroSection({ id }) {
  const theme = useTheme();
  const mdDown = useResponsive("down", "md");

  return (
    <RootStyle id={id}>
      <HeroSlider />
      {/* <DataContainer>
        <Typography
          variant={mdDown ? "h3" : "h1"}
          textAlign="center"
          fontWeight="700"
          fontFamily='"Merriweather", serif'
        >
          Your Child's Safety,
          <br /> Our Priority
        </Typography>
        <Typography variant="h5" textAlign="center" fontWeight="700" mt={2}>
          Welcome to{" "}
          <span style={{ color: theme.palette.primary.main }}>
            Najmat Al Najah School Bus Transportation LLC
          </span>
        </Typography>
        <Typography variant="body1" textAlign="center" color="text.secondary">
          Where Technology Drives Safety: GPS, RFID & CCTV - Your Child's Secure Journey.
        </Typography>

        <div style={{ marginTop: 64 }}>
          <ContactBtn />
        </div>
      </DataContainer>
      <ImageContainer />
      <ImageContainer2 /> */}
    </RootStyle>
  );
}

export default HeroSection;
