import { Types } from '../actionTypes';

const initialState = {
  currentLocation: [0.0, 0.0],
  AllGeofencesLocation: [],
  validGeofence: false,
};

const updateState = (state, change) => ({
  ...state,
  [change.key]: change.value,
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.SET_CURRENTLOCATION:
      return {
        ...state,
        currentLocation: action.payload,
      };
    case Types.SET_ALLGEOFENCE:
      return {
        ...state,
        AllGeofencesLocation: action.payload,
      };
    case Types.SET_GEOFENCE_VALIDITY:
      return {
        ...state,
        validGeofence: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
