import React from "react";
import { Typography, Grid, Card, CardContent, Button } from "@mui/material";
import jobs from "./jobs";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const Careers = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} >
          <Typography variant="h5" mb={3}>
            Apply for a Job
          </Typography>
          <form>
            <TextField
              label="Name"
              variant="outlined"
              fullWidth
              margin="normal"
              required
            />
            <TextField
              label="Email Address"
              variant="outlined"
              fullWidth
              margin="normal"
              required
            />
            <TextField
              label="Position"
              variant="outlined"
              fullWidth
              margin="normal"
              required
            />
            <InputLabel htmlFor="resume" style={{ marginBottom: "8px" }}>
              Resume
            </InputLabel>
            <input
              type="file"
              accept=".pdf, .doc, .docx"
              style={{ marginBottom: "20px" }}
            />
            <Button variant="contained" color="primary" type="submit">
              Submit
            </Button>
          </form>
        </Box>
      </Modal>

      <Grid container justifyContent="center" py={jobs.length === 0 ? 20 : 10}>
        <Grid item xs={10} sm={8} md={6}>
          <Typography
            variant="h3"
            textAlign="center"
            fontWeight="700"
            fontFamily='"Merriweather", serif'
            gutterBottom
          >
            Careers
          </Typography>
          {jobs.length === 0 ? (
            <Typography variant="body1" align="center" gutterBottom>
              Currently, there are no available job opportunities. We appreciate your interest and encourage you to stay connected for future updates and potential openings. Thank you for your patience and understanding.
            </Typography>
          ) : (
            jobs.map((job) => (
              <Card key={job.id} style={{ marginBottom: "20px" }}>
                <CardContent sx={{ padding: "30px" }}>
                  <Typography variant="h5" gutterBottom>
                    {job.title}
                  </Typography>
                  <Typography variant="body1">{job.description}</Typography>
                  <Grid container justifyContent="flex-end">
                    <Button onClick={handleOpen} variant="contained" color="primary" type="submit">
                      Apply
                    </Button>
                  </Grid>
                </CardContent>
              </Card>
            ))
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default Careers;
