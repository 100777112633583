import React, { useEffect, useState } from "react";
import SpaceFill from "components/spacefill";

import { RootStyle, ToolbarStyle, LogoContainer } from "./styles";
import { IconButton } from "@mui/material";
import Iconify from "components/iconify";
// logo image
import Logo from "assets/images/logos/logo.png";
import { useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";

function navbar({ handleDrawerOpen }) {
  const theme = useTheme();

  return (
    <RootStyle
      sx={{
        borderBottom: `1px solid ${theme.palette.divider}`,
      }}
    >
      <ToolbarStyle>
        <div style={{ marginRight: 32 }}>
          <IconButton onClick={handleDrawerOpen}>
            <Iconify icon="nimbus:menu" width={30} />
          </IconButton>
        </div>
        <LogoContainer>
          <Link to="/">
            <img
              src={Logo}
              alt="Logo"
              style={{
                transform: "scale(0.8)",
              }}
            />
          </Link>
        </LogoContainer>
        <SpaceFill />
      </ToolbarStyle>
    </RootStyle>
  );
}

export default navbar;
