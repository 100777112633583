import TransportForm from "assets/documents/transport-form-najmath.pdf";
import TransportAgreementForm from "assets/documents/nbt-transport-agreement.pdf";
import DPSRAKBUSROUTES from "assets/documents/dps-rak-bus-routes2.pdf";
import DPSSHJSNRBUSROUTES from "assets/documents/BUS-ROUTES-DPS-SHARJAH-senior3.pdf";
import DPSSHJPRIMBUSROUTES from "assets/documents/BUS-ROUTES-DPS-SHARJAH-primary2.pdf";
import DPSSHJPRIMDD from "assets/documents/DRIVER-DETAILS-DPS-PRIMARY2.pdf";
import DPSSHJSNRMDD from "assets/documents/DRIVER-DETAILS-DPS-SENIOR.pdf";
import ASBT from "assets/documents/Transport-form-ASBT.pdf"
import NBT from "assets/documents/Transport-form-NBT.pdf"
import STAFFASBT from "assets/documents/Transport-form-for-staff-ASBT.pdf"
import STAFFNBT from "assets/documents/Transport-form-for-staff-NBT.pdf"
import DPSRAKDRIVERDETAILS from "assets/documents/DRIVER-ROUTE-DETAILS-DPS-RAK-AY-24-25-2.pdf"

export const handleDownloadTraForm = () => {
    // const link = document.createElement("a");
    // link.href = TransportForm;
    // link.click();
    window.open(TransportForm)
};

export const handleDownloadASBT= () => {
    // const link = document.createElement("a");
    // link.href = TransportForm;
    // link.click();
    window.open(ASBT)
};
export const handleDownloadNBT= () => {
    // const link = document.createElement("a");
    // link.href = TransportForm;
    // link.click();
    window.open(NBT)
};
export const handleDownloadSTAFFASBT= () => {
    // const link = document.createElement("a");
    // link.href = TransportForm;
    // link.click();
    window.open(STAFFASBT)
};
export const handleDownloadSTAFFNBT= () => {
    // const link = document.createElement("a");
    // link.href = TransportForm;
    // link.click();
    window.open(STAFFNBT)
};

export const handleDownloadTraAgrForm = () => {
    // const link = document.createElement("a");
    // link.href = TransportAgreementForm;
    // link.click();
    window.open(TransportAgreementForm)
};

export const handleDownloadTraAgrFormRAK = () => {
    // const link = document.createElement("a");
    // link.href = TransportAgreementForm;
    // link.click();
    window.open(TransportAgreementForm)
};

export const handleDownloadDPSRAKRoutes = () => {
    // const link = document.createElement("a");
    // link.href = DPSRAKBUSROUTES;
    // link.click();
    window.open(DPSRAKBUSROUTES)

}

export const handleDownloadDPSSHJSNRRoutes = () => {
    // const link = document.createElement("a");
    // link.href = DPSSHJSNRBUSROUTES;
    // link.click();
    window.open(DPSSHJSNRBUSROUTES)

}
export const handleDownloadDPSSHJPRIRoutes = () => {
    // const link = document.createElement("a");
    // link.href = DPSSHJPRIMBUSROUTES;
    // link.click();
    window.open(DPSSHJPRIMBUSROUTES)

}

export const handleDownloadDPSRAKDRIVERDETAILS = () => {
    // const link = document.createElement("a");
    // link.href = DPSSHJPRIMBUSROUTES;
    // link.click();
    window.open(DPSRAKDRIVERDETAILS)

}


export const handleDownloadDPSRAKDD = () => {
    // const link = document.createElement("a");
    // link.href = DPSRAKBUSROUTES;
    // link.click();
    window.open(DPSRAKBUSROUTES)

}

export const handleDownloadDPSSHJSNRDD = () => {
    // const link = document.createElement("a");
    // link.href = DPSSHJSNRMDD;
    // link.click();
    window.open(DPSSHJSNRMDD)

}
export const handleDownloadDPSSHJPRIDD = () => {
    // const link = document.createElement("a");
    // link.href = DPSSHJPRIMDD;
    // link.click();
    window.open(DPSSHJPRIMDD)

}


