import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { RootFooterStyle } from "./styles";
import { Grid, Stack, Typography } from "@mui/material";

import {} from "hooks/downloads"

// logo image
import Logo from "assets/images/logos/logo.png";
import { useTheme } from "@mui/material/styles";

const Features = [
  {
    caption: "Quick Links",
    links: [
      {
        title: "About Us",
        path: "/about-us",
      },
      {
        title: "Our Services",
        path: "/services/service-summary",
      },
      {
        title: "Fees",
        path: "/fees/details",
      },
      {
        title: "Payment Details",
        path: "/fees/payment-modes",
      },
    ],
  },
  {
    caption: "Quick Links",
    links: [
      {
        title: "Contact Us",
        path: "/contact-us",
      },
      {
        title: "Careers",
        path: "/careers/list",
      },
      {
        title: "FAQs (Bus route)",
        path: "/faq/logistics",
      },
      {
        title: "FAQs (Fees)",
        path: "/faq/fees",
      },
    ],
  },
  
];


function Footer() {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [shrinkNavbar, setShrinkNavbar] = useState(false);
  const theme = useTheme();

  return (
    <RootFooterStyle sx={{backgroundColor: "#f0f2f5"}}>
      <Grid container spacing={5} padding={3} sx={{display: "flex", justifyContent:"space-around"}}>
        <Grid item xs={12} md={6} lg={3} >
        <Grid item xs={12} md={6} lg={6} sx={{ display: "flex", flexDirection: "column", justifyContent: { xs: "center", md: "start" } }} my={3}>
  <img
    src={Logo}
    alt="Logo"
    style={{
      transition: "0.1s linear",
      transform: shrinkNavbar ? "scale(0.8)" : "scale(1.2)",
      maxWidth: "300px", // Set the max width to 300 pixels
      height: "auto", // Maintain aspect ratio
      margin: "0 auto", // Center horizontally
    }}
  />
</Grid>

        <Grid item xs={12} md={12} lg={12} sx={{ display: "flex", alignItems: "center",justifyContent: { xs: "center", md: "start" } }}>
          <Typography variant="body2">© 2024 Njamat Al Najah School Bus Transportation LLC</Typography>
        </Grid>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sx={{ display: "flex", alignItems: "center" }}>
  <Stack
    sx={{ width: "100%", mt: 2 }}
    direction="row"
    justifyContent="space-between"
    spacing={2}
    alignItems="center"
  >
    {Features.map((group) => (
      <div key={group.caption}>
        <Typography variant={"h5"} fontWeight="300" gutterBottom fontFamily='"Merriweather", serif'>
          {group.caption}
        </Typography>
        {group.links.map((item) => (
          <div key={item.path}>
            <Link to={item.path} hre style={{ color: theme.palette.text.secondary }}>
              {item.title}
            </Link>
          </div>
        ))}
      </div>
    ))}
  </Stack>
</Grid>


      </Grid>
    </RootFooterStyle>
  );
}

export default Footer;
