// @mui
import { alpha, styled } from '@mui/material/styles';
import {
  Paper
} from '@mui/material';

// ----------------------------------------------------------------------

export function bgBlur(props) {
  const color = props?.color || '#000000';
  const blur = props?.blur || 6;
  const opacity = props?.opacity || 0.8;
  const imgUrl = props?.imgUrl;

  if (imgUrl) {
    return {
      position: 'relative',
      backgroundImage: `url(${imgUrl})`,
      '&:before': {
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 9,
        content: '""',
        width: '100%',
        height: '100%',
        backdropFilter: `blur(${blur}px)`,
        WebkitBackdropFilter: `blur(${blur}px)`,
        backgroundColor: alpha(color, opacity),
      },
    };
  }

  return {
    backdropFilter: `blur(${blur}px)`,
    WebkitBackdropFilter: `blur(${blur}px)`,
    backgroundColor: alpha(color, opacity),
  };
}

// ----------------------------------------------------------------------

export function bgGradient(props) {
  const direction = props?.direction || 'to bottom';
  const startColor = props?.startColor;
  const endColor = props?.endColor;
  const imgUrl = props?.imgUrl;
  const color = props?.color;

  if (imgUrl) {
    return {
      background: `linear-gradient(${direction}, ${startColor || color}, ${endColor || color}), url(${imgUrl})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
    };
  }

  return {
    background: `linear-gradient(${direction}, ${startColor}, ${endColor})`,
  };
}

// ----------------------------------------------------------------------

export function textGradient(value) {
  return {
    background: `-webkit-linear-gradient(${value})`,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  };
}

// ----------------------------------------------------------------------

export function filterStyles(value) {
  return {
    filter: value,
    WebkitFilter: value,
    MozFilter: value,
  };
}

// ----------------------------------------------------------------------

export const hideScrollbarY = {
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  overflowY: 'scroll',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
};

// ----------------------------------------------------------------------

export const hideScrollbarX = {
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  overflowX: 'scroll',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
};

// ----------------------------------------------------------------------

export const slimScrollBar = {
  "::-webkit-scrollbar": {
    width: 5,
    height: 5,
    background: "rgba(255, 255, 255, 0.3)"
  },

  "::-webkit-scrollbar-track": {
    width: 5,
    borderRadius: 10,
    background: "#F5F5F5"
  },

  "::-webkit-scrollbar-thumb": {
    borderRadius: 10,
    background: "rgba(0, 0, 0, 0.2)"
  },

  "::-webkit-scrollbar-thumb:window-inactive": {
    background: "rgba(255, 255, 255, 0.3)"
  }
};


export const randomColors = () => {
  // Generate random values in a limited range for each color channel
  const red = Math.floor(Math.random() * 156 + 100); // 100-255
  const green = Math.floor(Math.random() * 156 + 100); // 100-255
  const blue = Math.floor(Math.random() * 156 + 100); // 100-255

  // Convert the RGB values to a CSS-friendly color format
  const pastelColor = `rgb(${red}, ${green}, ${blue})`;

  return pastelColor;
}

export const StyledTableContainer = styled(Paper)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.spacing(2),
  overflow: "hidden"
}));

export const StyledDatepicker = styled("div")(({ theme }) => ({
  width: '120%',
  display: "flex",
  padding: "0px !important",
  flexDirection: "column",
  "& .border": {
    display: "none"
  },
  "& .date-container": {
    height: 23,
    marginTop: '-4px !important',

    "& .p-calendar": {
      "& .p-inputtext": {


        padding: '0px !important',
        paddingBottom: '0px !important',
        border: `0px !important`,
      }
    }
  },
  "& .p-calendar.p-component.p-inputwrapper.p-inputwrapper-filled": {
    width: '100%',
  }

}));
