const jobData = [
  // {
  //   id: 1,
  //   title: "School Bus Driver",
  //   description:
  //     "We are looking for a responsible and experienced school bus driver to transport students safely to and from school...",
  // },
  
];

export default jobData;
