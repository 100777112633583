import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import axios from "axios";
import GeolocationReducer from "../reducers/geofences";
import AttendanceReducer from "../reducers/Attendance";


const rootReducer = combineReducers({
    geolocation: GeolocationReducer,
    attendance: AttendanceReducer
});


const configureStore = createStore(rootReducer, applyMiddleware(thunk));



export default configureStore;