import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { Box, Typography, useTheme } from "@mui/material";
import useResponsive from "hooks/useResponsive";


const testimonials = [
    {
        message: "I am writing to express my sincere appreciation for the exceptional service provided by one of your bus drivers, \nMr. Mahboob Ullah (Bus No. 50).\nSince we do not have school transportation where we stay, we travel 30 mins before we reach our pick up point at Max Metro. There have been times that we have reached a minute or so late but he has never created a fuss over it. He has consistently demonstrated a friendly and courteous demeanor showing patience and kindness, which is so valuable during the morning hours. I wanted to ensure that his kindness is recognized and appreciated. Please extend my heartfelt thanks to him for his exceptional service.",
        name: "Adeline",
        position: "Parent"
    }
    // {
    //     message: "We had a nice experience, my kids are still talking about it! Thank you for the free gift hampers and vouchers as these are very beneficial for our children during the summer holidays and keeps them entertained and busy. Your staff were very kind and helpful. Thank you very much and we hope to attend future events too.",
    //     name: "Fouzia Begum",
    //     position: "Parent"
    // }
];

const TestimonialSlider = () => {
    const mdDown = useResponsive("down", "md");
  const theme = useTheme();

  return (
    <Box sx={{ py: 8, backgroundColor: "#fff" }}>
        <div style={{ width: "100%", height: "100%", width: "100%", margin: "0 auto" }}>
          <Typography variant={mdDown ? "h3" : "h1"} textAlign="center" fontWeight="700">
          Testimonials
          </Typography>
        </div>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false
        }}
        loop={true}
        pagination={{
          clickable: true
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        {testimonials.map((testimonial, index) => (
          <SwiperSlide key={index}>
            <Box
              sx={{
                px: 2,
                py: 4,
                maxWidth: 800,
                height: 300, // Set the fixed height here
                margin: "0 auto",
                mt: 4, // Add top margin
                mb: 4,  // Add bottom margin
                textAlign: "center",
                backgroundColor: theme.palette.background.paper,
                boxShadow: theme.shadows[3],
                borderRadius: theme.shape.borderRadius,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center"
              }}
            >
              <Typography variant="body1" color="textSecondary" sx={{ mb: 3 }}>
                                {testimonial.message.split('\n').map((text, index) => (
                                    <React.Fragment key={index}>{text}<br/></React.Fragment>
                                ))}
                            </Typography>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                {testimonial.name}
              </Typography>
              <Typography variant="subtitle2" color="textSecondary">
                {testimonial.position}
              </Typography>
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

export default TestimonialSlider;
