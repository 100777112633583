import PropTypes from "prop-types";
import { useState } from "react";
import { NavLink as RouterLink, matchPath, useLocation, useNavigate } from "react-router-dom";
// @mui
import {
  Box,
  List,
  ListItemText,
  Collapse,
  Stack,
  Popover,
  Menu,
  useThemeProps,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
//
import { StyledNavItem, StyledNavSubItem, StyledNavItemIcon } from "./styles";
import Iconify from "components/iconify";

import { NestedDropdown } from "mui-nested-menu";
import { navconfigNew as navConfig } from "../config";
// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
  setHasChildren: PropTypes.func,
};

export default function NavSection({ ...other }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const match = (path) => (path ? !!matchPath({ path, end: false }, pathname) : false);


  const data = navConfig(navigate);

  return (
    <Box {...other}>
      <List
        disablePadding
        sx={{ p: 1.2, display: "inline-flex", justifyContent: "center", alignItems: "center" }}
      >
        {data.map((item) => {
          return <NavItem key={item.path} item={item} active={match} />;
        })}
      </List>
    </Box>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  setHasChildren: PropTypes.func,
  active: PropTypes.func,
};

function MenuChild({ item, active, activeSubStyle }) {
  const { label, path, children } = item;
  const isActiveRoot = active(path);
  const [open, setOpen] = useState(isActiveRoot);
  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  return (
    <div style={{ position: "relative" }}>
      <StyledNavItem
        onClick={handleOpen}
        sx={{
          mr: 0,
          ...(isActiveRoot && activeSubStyle),
        }}
      >
        <ListItemText primary={label} />
        <Stack />
        <Iconify
          icon="solar:round-alt-arrow-right-line-duotone"
          sx={{
            height: 18,
            width: 18,
            transform: open && "rotate(90deg)",
            transition: "0.2s ease-in-out",
          }}
        />
      </StyledNavItem>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {children.map((item) => {
            const { label, path, action } = item;
            const isActiveSub = active(path);

            return (
              <StyledNavSubItem
                key={`${path}-${label}`}
                component={RouterLink}
                to={!action && path}
                onClick={action && action}
                sx={{
                  ...(isActiveSub && activeSubStyle),
                  padding: "0 6px",
                }}
              >
                <StyledNavItemIcon>
                  <Box
                    component="span"
                    sx={{
                      width: 4,
                      height: 4,
                      display: "flex",
                      borderRadius: "50%",
                      alignItems: "center",
                      justifyContent: "center",
                      bgcolor: "text.disabled",
                      transition: (theme) => theme.transitions.create("transform"),
                      ...(isActiveSub && {
                        transform: "scale(2)",
                        bgcolor: "primary.main",
                      }),
                    }}
                  />
                </StyledNavItemIcon>
                <ListItemText disableTypography primary={label} />
              </StyledNavSubItem>
            );
          })}
        </List>
      </Collapse>
    </div>
  );
}

function NavItem({ item, active }) {
  const { label, path, items } = item;

  const theme = useTheme();
  const isActiveRoot = active(path);

  const [open, setOpen] = useState(null);
  const handleOpen = (event) => {
    if (!!open) {
      setOpen(null);
    }
    setOpen(event.currentTarget);
  };
  const handleClose = () => {
    setOpen(null);
  };

  const activeRootStyle = {
    // background: alpha(theme.palette.primary.main, 0.2),
    fontWeight: "fontWeightMedium",
    border: "0px",
    "& svg": {
      color: theme.palette.primary.main,
    },
    "&:before": {
      content: '""',
      position: "absolute",
      background: theme.palette.primary.main,
      width: "100%",
      height: 2,
      left: "50%",
      transform: "translateX(-50%)",
      bottom: 0,
      borderRadius: "0px 10px 10px 0px",
    },
  };

  const activeSubStyle = {
    color: theme.palette.primary.main,
    fontWeight: "fontWeightMedium",
    // background: alpha(theme.palette.primary.main, 0.2),
  };

  if (items) {
    return (
      <div style={{ position: "relative" }}>
        <NestedDropdown
          menuItemsData={item}
          MenuProps={{
            elevation: 10,
            disableScrollLock: true,
            PaperProps: { sx: { border: `1px solid ${theme.palette.divider}` } },
          }}
          ButtonProps={{
            variant: "text",
            sx: {
              ...theme.typography.subtitle2,
              padding: theme.spacing(1),
              position: "relative",
              textTransform: "capitalize !important",
              color: `${theme.palette.text.primary} !important`,
              borderRadius: "13px !important",
              margin: "0 auto",
              fontSize: 16,
              fontWeight: "500",
              marginRight: theme.spacing(2),
            },
          }}
          onClick={() => console.log("Clicked")}
        />

        {/* <StyledNavItem
          onMouseEnter={handleOpen}
          sx={{
            ...(isActiveRoot && activeRootStyle),
          }}
        >
          <ListItemText primary={label} />
          <Stack />
        </StyledNavItem>
        <Menu
          disableScrollLock
          onM
          open={Boolean(open)}
          anchorEl={open}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
          sx={{
            top: 30,
            "& .MuiBackdrop-root": {
              top: 120,
            },
          }}
          PaperProps={{
            onMouseLeave: () => setTimeout(handleClose, 200),
            sx: {
              p: 1,
              px: 2,
              mt: 0,
              width: 300,
              border: `1px solid ${theme.palette.primary.main}`,
            },
          }}
        >
          <List component="div" disablePadding>
            {children.map((item) => {
              const { label, path, children: subchildren } = item;
              const isActiveSub = active(path);

              if (subchildren) {
                return <MenuChild item={item} activeSubStyle={activeSubStyle} active={active} />;
              }

              return (
                <>
                  <StyledNavSubItem
                    key={path}
                    component={RouterLink}
                    to={path}
                    sx={{
                      ...(isActiveSub && activeSubStyle),
                      padding: "0 6px",
                    }}
                  >
                    <ListItemText primary={label} />
                  </StyledNavSubItem>
                </>
              );
            })}
          </List>
        </Menu> */}
      </div>
    );
  }

  return (
    <StyledNavItem
      component={RouterLink}
      to={path}
      sx={{
        ...(isActiveRoot && activeRootStyle),
      }}
    >
      <ListItemText primary={label} sx={{ fontSize: 14 }} />
    </StyledNavItem>
  );
}
