//images
import Features from "assets/images/hero/featurs.png";

export const WhyNBTConfig = {
  head: "Why Choose NBT?",
  desc: "Choose NBT as your transportation partner for a clear vision prioritizing the safety and comfort of our clients. With an impressive safety record and an unwavering dedication to safety at our core, we ensure comprehensive training for our drivers. Our pre-service program offers 30 hours of rigorous training, while our in-service and behind-the-wheel program provides new drivers with an extensive 80-hour training in their first year. Committed to safety education, we pledge to inform students and parents about safe school bus practices. At NBT, we pride ourselves on a first-class preventive maintenance system, fostering excellent employee relations and enabling effective communication between our drivers and logistics crew. Join us for a partnership built on safety, expertise, and dedication.",
  img: Features,
};

export const VisionConfig = {
  head: `Our Vision`,
  desc: "Providing Safe and Secure School Bus Transportation. Our vision is to provide solutions for an increasingly congested world - keeping people moving and communities prospering. Piloted by our core keys, we are a customer-centric organization with focused flexibility, accessibility, and a solid commitment to our customers’ success. To deliver our vision we develop transportation services that enable us to make the best possible impact on the communities we serve, the environment we live in, and the economy we fuel.",
};

export const SafetyConfig = {
  head: `Guiding Principles for Success`,
  desc: `Our ethos revolves around customer success, unwavering safety, mutual support, and relentless pursuit of excellence. We prioritize accountability in decisions, setting the highest standards to drive success for all stakeholders.`,
  steps: [
    {
      head: "Commitment to our customers",
      desc: "We keep our customers at the heart of everything we do. This extends beyond customer satisfaction; we focus on customer success.",
      icon: "covid:personal-hygiene-hand-sanitizer-spray",
    },
    {
      head: "Supportive of each other",
      desc: "We trust each other to deliver and work to help one another succeed.",
      icon: "solar:health-broken",
    },
    {
      head: "Dedication to safety standards",
      desc: "Always first in mind, safety is our way of life. Reliable student transportation services that maximizes operational efficiencies and cost savings without sacrificing control or accountability.",
      icon: "covid:personal-hygiene-hand-sanitizer-spray",
    },
    {
      head: "Accountability for performance",
      desc: "Every decision matters. We do the right things to drive success for our partners, which in turn will allow us to achieve our goals.",
      icon: "solar:health-broken",
    },
    {
      head: "Setting the highest standards",
      desc: "We want to be the best, continually finding new and better ways to help our partners, communities and employees succeed.",
      icon: "covid:personal-hygiene-hand-sanitizer-spray",
    },
  ],
};

export const CTAConfig = {
  head: "With a clear vision for the safety and comfort of our clients. ",
  desc: "We maintain an impressive safety record due to our uncompromising dedication to safety in all our operations. Furthermore,we ensure that all new drivers undergo a pre-service training program, providing them with at least 30 hours of training before they commence their employment.",
};
