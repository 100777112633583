import React, { useState } from "react";
import { TextField, Button, Grid, Typography } from "@mui/material";
import emailjs from '@emailjs/browser';
import toast, { Toaster } from "react-hot-toast";

const ContactForm = () => {

  const [userData, setUserData] =  useState({
    from_name: '',
    user_email: '',
    user_telephone: '',
    message: ''
  })

  const handleChnge = (e) => {
    e.preventDefault()

    setUserData((prev) => ({...prev , [e.target.name]: e.target.value}))

  }

   //send button click function
   const handleClick = (e) => {
    
    e.preventDefault()
    // setBtnText("Sending...")
    console.log(JSON.stringify(userData))
  //email send
  emailjs.send(process.env.REACT_APP_SERVICE_ID,process.env.REACT_APP_TEMPLATE_ID, userData, process.env.REACT_APP_PUBLIC_KEY)
    .then((response) => {
       console.log('SUCCESS!', response.status, response.text);
       setUserData({
        from_name: '',
        user_email: '',
        user_telephone: '',
        message: ''
      });
       toast.success("Email send Successfully");
       window.location.reload();
    }, (err) => {
       console.log('FAILED...', err);
       toast.error(err.text);
    });

  }


  return (
    <Grid container justifyContent="center" my={10}>
      <Toaster />
      <Grid item xs={10} sm={8} md={6}>
        <Typography variant="h4" gutterBottom>
          Contact Us
        </Typography>
        <form  onSubmit={handleClick}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField label="Name" variant="outlined" fullWidth required onChange={handleChnge} value={useState.from_name} name="from_name"/>
            </Grid>
            <Grid item xs={12}>
              <TextField label="Email" variant="outlined" fullWidth required onChange={handleChnge} value={useState.user_email} name="user_email"/>
            </Grid>
            <Grid item xs={12}>
              <TextField label="Telephone" variant="outlined" fullWidth required onChange={handleChnge} value={useState.user_telephone} name="user_telephone"/>
            </Grid>
            <Grid item xs={12}>
              <TextField  placeholder="Please mention the Student name, Grade, Registration Number and type your queries here." variant="outlined" fullWidth multiline rows={4} onChange={handleChnge} required value={useState.message} name="message"/>
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary" fullWidth>
                SEND
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default ContactForm;
