import React, { useState, useEffect } from "react";
// @mui
import { Grid, Typography, useTheme, Card, Paper, Container, Divider, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import Iconify from "components/iconify";
import * as XLSX from "xlsx";

import DATASRC from "assets/documents/Data-najmath.xlsx";

const fetchData = async (setState) => {
  try {
    const response = await fetch(DATASRC);
    const blob = await response.blob();

    const reader = new FileReader();
    reader.onload = (event) => {
      const data = event.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0]; // Assuming you want the first sheet
      const worksheet = workbook.Sheets[sheetName];
      const excelData = XLSX.utils.sheet_to_json(worksheet);
      if (excelData) {
        const FinalData = excelData.map((dataitem, index) => ({
          id: index + 1,
          icon: "lets-icons:calendar-fill",
          ...dataitem,
        }));
        setState(FinalData);
      }
    };
    reader.readAsBinaryString(blob);
  } catch (error) {
    console.error("Error occurred while reading the file:", error);
  }
};

const Step = ({ data, zero }) => {
  const theme = useTheme();

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between", // Align content vertically
        minHeight: 0, // Allow card to shrink
        p: 3,
        borderRadius: 3,
        boxShadow: zero && "none",
        width: "100%",
        height: "100%", // Ensure the card takes full height
      }}
    >
      <Typography variant="h4" sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>
        {data.name}
      </Typography>
      <Typography variant="body2" fontWeight="400" sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>
        {data.designation}
      </Typography>
      <Typography variant="body2" fontWeight="400" sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>
        {data.unit}
      </Typography>
      <Divider />
      <Stack sx={{ flexGrow: 1 }}> {/* Ensure Stack takes remaining space */}
        <Stack direction="row" alignItems="center" spacing={2}>
          <Iconify icon="material-symbols:mail-outline" width={16} color="text.secondary" />
          <Typography component="span">{data.email}</Typography>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Iconify icon="ph:phone" width={16} color="text.secondary" />
          <Typography component="span">{data.phone}</Typography>
        </Stack>
        {data.mobile && (
          <Stack direction="row" alignItems="center" spacing={2}>
            <Iconify icon="mynaui:mobile" width={16} color="text.secondary" />
            <Typography component="span">{data.mobile}</Typography>
          </Stack>
        )}
      </Stack>
    </Card>
  );
};


function HeroSection({ white = false, zero = false }) {
  const theme = useTheme();
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    if (!steps.length) {
      fetchData(setSteps);
    }
  }, []);

  return (
    <div style={{ background: white && theme.palette.background.paper, paddingBottom: 80 }}>
      <Container maxWidth="xl">
        {steps && (
          <Grid container spacing={2} justifyContent="center">
            {steps.map((dataitem, index) => {
              return (
                <Grid item lg={3} xs={12} md={4} key={index}>
                  <Step data={dataitem} zero={zero} />
                </Grid>
              );
            })}
          </Grid>
        )}
      </Container>
    </div>
  );
}


export default HeroSection;
