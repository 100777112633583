import { useRoutes, Navigate } from "react-router-dom";

// layouts
import FullScreenLayout from "../layouts/baseLayout";
import SearchLayout from "../layouts/searchLayout";

// pages

import HomePage from "pages/Home";
import AboutUs from "pages/AboutUs";
import BusRoutes from "pages/BusRoutes";
import BusRoutesSearchPage from "pages/BusRoutes/SearchPage";
import Services from "pages/Services";
import DPSSHJFEES from "pages/Fees/dpsShj";
import DPSRAKFEES from "pages/Fees/dpsRAK";
import FeesPage from "pages/Fees";
import PaymentModes from "pages/PaymentModes";
import ContactUs from "pages/ContactUs";
import Page404 from "pages/404";
import FeesFAQs from "pages/FAQ/FeesFAQ";
import LogisticsFAQ from "pages/FAQ/LogisticsFAQ";
import Careers from "pages/Careers/careers";
import Maintance from "pages/Maintenance/Index";
import TransportForm from "pages/Forms/TransportForm";
// import Maintance from "pages/Maintenance"

export default function Routes() {
  return useRoutes([
    {
      path: "/services",
      element: <FullScreenLayout />,
      children: [
        { path: "bus-routes", element: <BusRoutes /> },
        { path: "service-summary", element: <Services /> },
      ],
    },

    {
      path: "/services/bus-routes/search",
      element: <SearchLayout />,
      children: [{ path: "", element: <BusRoutesSearchPage /> }],
    },
    {
      path: "/fees",
      element: <FullScreenLayout />,
      children: [
        { path: "details", element: <FeesPage /> },
        { path: "payment-modes", element: <PaymentModes /> },
        { path: "dps-sharjah", element: <DPSSHJFEES /> },
        { path: "dps-rak", element: <DPSRAKFEES /> },
      ],
    },
    {
      path: "/faq",
      element: <FullScreenLayout />,
      children: [
        { path: "fees", element: <FeesFAQs /> },
        { path: "logistics", element: <LogisticsFAQ /> }
      ],
    },
    {
      path: "/careers",
      element: <FullScreenLayout />,
      children: [{ path: "list", element: <Careers /> }],
    },
    {
      path: "/services",
      element: <FullScreenLayout />,
      children: [
        { path: "transport", element: <Services /> },
         { path: "maintenance", element: <Maintance /> },
      ],
    },
    {
      path: "/forms",
      element: <FullScreenLayout />,
      children: [
        { path: "transport-form-parent", element: <TransportForm /> },
      ],
    },
    {
      path: "/",
      element: <FullScreenLayout />,
      children: [
        { path: "/", element: <HomePage /> },
        { path: "/about-us", element: <AboutUs /> },
        { path: "/services", element: <AboutUs /> },
        { path: "/contact-us", element: <ContactUs /> },
        { path: "*", element: <Page404 /> },
      ],
    },
  ]);
}
