import React, { useEffect, useState } from "react";
// @mui
import { Grid, Typography, useTheme, Card, Paper, Container } from "@mui/material";
import { styled } from "@mui/material/styles";
import Iconify from "components/iconify";
import * as XLSX from "xlsx";

// styled components
import FeeStructureSrc from "assets/documents/Fee structure-DPS-SHARJAH-2023-24-Najmat.xlsx";

const DefaultHeading = "This is the Heading";
const DefaultDescription = "";

const fetchData = async (setState) => {
  try {
    const response = await fetch(FeeStructureSrc);
    const blob = await response.blob();

    const reader = new FileReader();
    reader.onload = (event) => {
      const data = event.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[2]; // Assuming you want the first sheet
      const worksheet = workbook.Sheets[sheetName];
      const excelData = XLSX.utils.sheet_to_json(worksheet);
      if (excelData) {
        const FinalData = excelData.map((dataitem, index) => ({
          id: index + 1,
          icon: "lets-icons:calendar-fill",
          ...dataitem,
        }));
        setState(FinalData);
      }
    };
    reader.readAsBinaryString(blob);
  } catch (error) {
    console.error("Error occurred while reading the file:", error);
  }
};

const Step = ({ data, zero }) => {
  const theme = useTheme();
  return (
    <Card
      sx={{
        minHeight: "100%",
        display: "flex",
        flexDirection: "column",
        flex: 1,
        p: 3,
        alignItems: "center",
        borderRadius: 3,
        boxShadow: zero && "none",
      }}
    >
      {data.icon && (
        <div
          style={{
            width: 80,
            height: 80,
            background: theme.palette.background.default,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 100,
            marginBottom: 16,
          }}
        >
          <Iconify icon={data.icon} width={40} color={theme.palette.primary.main} />
        </div>
      )}
      <Typography variant="h5" textAlign="center">
        {data.paymentmode}
      </Typography>
    </Card>
  );
};

function HeroSection({
  heading = DefaultHeading,
  description = DefaultDescription,
  steps = [],
  white = false,
  zero = false,
  headerWidth = "100%",
}) {
  const theme = useTheme();

  const [Data, setData] = useState([]);

  useEffect(() => {
    if (!Data.length) {
      fetchData(setData);
    }
  }, []);

  return (
    <div style={{ background: white && theme.palette.background.paper }}>
      <Container maxWidth="sm" sx={{ py: 10 }}>
        <div style={{ width: "100%", height: "100%", width: headerWidth, margin: "0 auto" }}>
          <Typography variant="h1" textAlign="center" fontWeight="700">
            {heading}
          </Typography>
        </div>
        <div style={{ maxWidth: 800, margin: "0 auto" }}>
          <Typography variant="body1" textAlign="center" mt={2} color="text.secondary">
            {description}
          </Typography>
        </div>

        {Data && (
          <Grid
            container
            mt={5}
            flex={1}
            justifyContent="center"
            sx={{
              px: 0,
              visibility: "hidden",
              "& > *": {
                visibility: "visible",
                transition: "opacity 150ms linear 100ms, transform 150ms ease-in-out 100ms",
              },

              "&:hover > *": {
                opacity: 0.4,
              },
              "& > *:hover": {
                opacity: 1,
                transform: "scale(1.06)",
              },

              // "& .MuiGrid-item:hover ~ .MuiGrid-item": {
              //   opacity: 0.6,
              // },
            }}
          >
            {Data.map((dataitem, index) => {
              return (
                <Grid item lg={3} xs={1} md={4} mt={3} ml={3}>
                  <Step key={index} data={dataitem} zero={zero} />
                </Grid>
              );
            })}
          </Grid>
        )}
      </Container>
    </div>
  );
}

export default HeroSection;
