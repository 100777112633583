import {
  handleDownloadDPSRAKRoutes,
  handleDownloadDPSSHJSNRRoutes,
  handleDownloadDPSSHJPRIRoutes,
  handleDownloadDPSSHJSNRDD,
  handleDownloadDPSSHJPRIDD,
  handleDownloadTraAgrFormRAK,
  handleDownloadTraAgrForm,
  handleDownloadASBT,
  handleDownloadNBT,
  handleDownloadSTAFFASBT,
  handleDownloadSTAFFNBT,
  handleDownloadDPSRAKDRIVERDETAILS
} from "hooks/downloads";

export const navConfigDrawer = [
  {
    title: "About Us",
    path: "/about-us",
  },

  {
    title: "Our Services",
    path: "/services/service-summary",
  },

  {
    title: "Bus Route DPS Sharjah Primary",
    // action: handleDownloadDPSSHJPRIRoutes,
    path: "https://firebasestorage.googleapis.com/v0/b/najmat-fcf50.appspot.com/o/pdf%2FBUS-ROUTES-DPS-SHARJAH-primary2.pdf?alt=media&token=9780ca50-4e41-4507-8fd9-3b6eea590cb3"
  },
  {
    title: "Bus Route DPS Sharjah Senior",
    path: "https://firebasestorage.googleapis.com/v0/b/najmat-fcf50.appspot.com/o/pdf%2FBUS-ROUTES-DPS-SHARJAH-senior3.pdf?alt=media&token=a0247388-3cde-4566-b030-588c519deec8",
  },
  {
    title: "Bus Route DPS RAK",
    path: "https://firebasestorage.googleapis.com/v0/b/najmat-fcf50.appspot.com/o/pdf%2Fdps-rak-bus-routes2.pdf?alt=media&token=b52ec1bf-5696-42d8-917e-76145dc63bb5",
  },

  {
    title: "Driver Details DPS Sharjah Primary",
    path: "https://firebasestorage.googleapis.com/v0/b/najmat-fcf50.appspot.com/o/pdf%2FDRIVER-DETAILS-DPS-PRIMARY2.pdf?alt=media&token=4a29a0d6-1457-4790-a9ad-3302df044c91",
  },
  {
    title: "Driver Details DPS Sharjah Senior",  
    path: "https://firebasestorage.googleapis.com/v0/b/najmat-fcf50.appspot.com/o/pdf%2FDRIVER-DETAILS-DPS-SENIOR.pdf?alt=media&token=7277fe71-f626-4e6c-a231-2184c75286a2",
  },

  {
    title: "Fee Details",
    path: "/fees/details",
  },

  {
    title: "NBT Parents Agreement Form DPS Sharjah Primary",
    // action: handleDownloadTraAgrForm,
    path: "https://firebasestorage.googleapis.com/v0/b/najmat-49083.appspot.com/o/pdf%2Fnbt-transport-agreement.pdf?alt=media&token=7452bcdd-9ea8-43c2-847c-a1f66175c4fa",
  },
  {
    title: "NBT Parents Agreement Form DPS Sharjah Senior",
    // action: handleDownloadTraAgrForm,
    path: "https://firebasestorage.googleapis.com/v0/b/najmat-49083.appspot.com/o/pdf%2Fnbt-transport-agreement.pdf?alt=media&token=7452bcdd-9ea8-43c2-847c-a1f66175c4fa",
  },
  {
    title: "NBT Parents Agreement Form DPS RAK",
    // action: handleDownloadTraAgrFormRAK,
    path: "https://firebasestorage.googleapis.com/v0/b/najmat-49083.appspot.com/o/pdf%2Fnbt-transport-agreement.pdf?alt=media&token=7452bcdd-9ea8-43c2-847c-a1f66175c4fa",
  },
  {
    title: "Payment Details",
    path: "/fees/payment-modes",
  },
  {
    title: "Contact Us",
    path: "/contact-us",
  },
];

export const navConfig = [
  {
    title: "About Us",
    path: "/about-us",
  },
  {
    title: "Services",
    path: "/services",
    children: [
      {
        title: "Our Services",
        path: "/services/service-summary",
      },
      {
        title: "Bus Route Details",
        path: "",
        children: [
          {
            title: "DPS Sharjah Primary",
            action: handleDownloadDPSSHJPRIRoutes,
          },
          {
            title: "DPS Sharjah Senior",
            action: handleDownloadDPSSHJSNRRoutes,
          },
          {
            title: "DPS RAK",
            action: handleDownloadDPSRAKRoutes,
          },
        ],
      },
      {
        title: "Driver Details Download",
        path: "",
        children: [
          {
            title: "DPS Sharjah Primary",
            action: handleDownloadDPSSHJPRIDD,
          },
          {
            title: "DPS Sharjah Senior",
            action: handleDownloadDPSSHJSNRDD,
          },
          {
            title: "DPS RAK",
            action: handleDownloadDPSRAKDRIVERDETAILS,
          },
        ],
      },
    ],
  },
  {
    title: "Fees",
    path: "/fees",
    children: [
      {
        title: "Fee Details",
        path: "/fees/details",
      },
      {
        title: "NBT Parents Agreement Form",
        path: "",
        children: [
          {
            title: "DPS Sharjah Primary",
            action: handleDownloadTraAgrForm,
          },
          {
            title: "DPS Sharjah Senior",
            action: handleDownloadTraAgrForm,
          },
          {
            title: "DPS RAK",
            action: handleDownloadTraAgrFormRAK,
          },
        ],
      },
      {
        title: "Payment Details",
        path: "/fees/payment-modes",
      },
    ],
  },
  {
    title: "Contact Us",
    path: "/contact-us",
  },
];

export const navconfigNew = (navigate) => {
  return [
    {
      label: "Home",
      path: "/",
    },
    {
      label: "Our Services",
      path: "/services",
      items: [
        {
          label: "School transportation",
          path: "/transport",
          callback: () => navigate("/services/transport"),
        },
        {
          label: "Vehicle Maintenance",
          path: "/maintance",
          callback: () => navigate("/services/maintenance"),
        },
      ],
    },
    {
      label: "Bus Routes",
      path: "",
      items: [
        {
          label: "DPS Sharjah",
          items: [
            { label: "Primary Section", callback: handleDownloadDPSSHJPRIRoutes },
            { label: "Senior Section", callback: handleDownloadDPSSHJSNRRoutes },
          ],
        },
        {
          label: "DPS RAK",
          callback: handleDownloadDPSRAKRoutes,
        },
        {
          label: "Driver Details",
          items: [
            {
              label: "DPS Sharjah",
              items: [
                { label: "Primary Section", callback: handleDownloadDPSSHJPRIDD },
                { label: "Senior Section", callback: handleDownloadDPSSHJSNRDD },
              ],
            },
            {
              label: "DPS RAK",
              callback: handleDownloadDPSRAKDRIVERDETAILS,
            },
          ],
        },
        {
          label: "FAQs",
          path: "/faq/logistics",
          callback: () => navigate("/faq/logistics"),
        },
      ],
    },
    {
      label: "Forms",
      path: "",
      items: [
        {
          label: "Transport form - NBT",
          callback: handleDownloadNBT,
        },
        {
          label: "Transport form for staff - NBT",
          callback: handleDownloadSTAFFNBT,
        },
        
      ],
    },
    {
      label: "Fees",
      path: "/fees",
      items: [
        {
          label: "DPS Sharjah",
          path: "/fees/dps-sharjah",
          callback: () => navigate("/fees/dps-sharjah"),
        },
        {
          label: "DPS RAK",
          path: "/fees/dps-rak",
          callback: () => navigate("/fees/dps-rak"),
        },
        {
          label: "FAQs",
          path: "/faq/fees",
          callback: () => navigate("/faq/fees"),
        },
      ],
    },
    {
      label: "Careers",
      path: "/careers/list",
      callback: () => navigate("/careers/list"),
    },
  ];
};
