import React from "react";
import { Grid, Typography, useTheme, Container } from "@mui/material";

function BulletList() {
  const theme = useTheme();

  // Define the data directly inside the component
  const heading = "Experts IN";
  const bulletPoints = [
    "MECHANICAL WORKS",
    "VEHICLE WASHING",
    "ELECTRICAL AND AIR CONDITIONING",
    "RTA PASSING WORKS",
    "PERIODIC MAINTANENCE SERVICE",
    "BUS UPHOLSTEXT",
    "QUICK OIL CHANGE AND LUBRICATION SERVICE",
    "WINDOW TINTING",
    "WATER SERVICE - SHAMPOO AND GREASING",
    "STICKER 108S",
    "SCHOOL BUS MODIFICATION WORKS AS PER RTA SPECIFICATION",
    "SEAT MODIFICATION",
  ];
  const paragraph =
    "We use only genuine guaranteed and warranteed spareparts. Annual Service contract package for fleet customers";

  return (
    <Container maxWidth="sm" sx={{ py: 10 }}>
      <div style={{ width: "100%", maxWidth: 800, margin: "0 auto" }}>
        <Typography variant="h3" textAlign="center" fontWeight="700">
          {heading}
        </Typography>
        <ul style={{ paddingLeft: '20px', paddingRight: '20px' }}>
        {bulletPoints.map((point, index) => (
          <li key={index}>{point}</li>
        ))}
      </ul>
        <Typography variant="body1" textAlign="center" mt={2} color="text.secondary">
          {paragraph}
        </Typography>
      </div>
    </Container>
  );
}

export default BulletList;
