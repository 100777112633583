import PropTypes from "prop-types";
import { useState } from "react";
import { NavLink as RouterLink, matchPath, useLocation } from "react-router-dom";
// @mui
import { Box, List, ListItemText, Collapse, Stack } from "@mui/material";
import { alpha, useTheme } from "@mui/material/styles";
//
import { StyledNavItem, StyledNavItemIcon, StyledNavSubItem } from "./styles";
import Iconify from "components/iconify";

// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
  setHasChildren: PropTypes.func,
};

export default function NavSection({ data = [], setHasChildren, ...other }) {
  const { pathname } = useLocation();
  const match = (path) => (path ? !!matchPath({ path, end: false }, pathname) : false);

  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1.2 }}>
        {data.map((item) => {
          return (
            <NavItem key={item.path} item={item} active={match} setHasChildren={setHasChildren} />
          );
        })}
      </List>
    </Box>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  setHasChildren: PropTypes.func,
  active: PropTypes.func,
};

function NavItem({ item, active }) {
  const { title, path, icon, info, children } = item;

  const theme = useTheme();
  const isActiveRoot = active(path);
  const [open, setOpen] = useState(isActiveRoot);
  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const activeRootStyle = {
    // background: alpha(theme.palette.primary.main, 0.2),
    fontWeight: "fontWeightMedium",
    border: "0px",
    "& svg": {
      color: theme.palette.primary.main,
    },
    "&:before": {
      content: '""',
      position: "absolute",
      background: theme.palette.primary.main,
      height: "100%",
      width: 5,
      left: -10,
      borderRadius: "0px 10px 10px 0px",
    },
  };

  const activeSubStyle = {
    color: theme.palette.primary.main,
    fontWeight: "fontWeightMedium",
    // background: alpha(theme.palette.primary.main, 0.2),
  };

  const HandleDrawerToggle = () => {
    setOpen((prev) => !prev);
  };

  if (children) {
    return (
      <div style={{ position: "relative" }}>
        <StyledNavItem
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && activeRootStyle),
          }}
        >
          <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>
          <ListItemText primary={title} />
          <Stack />
          <Iconify
            icon="solar:round-alt-arrow-right-line-duotone"
            sx={{
              height: 18,
              width: 18,
              transform: open && "rotate(90deg)",
              transition: "0.2s ease-in-out",
            }}
          />
        </StyledNavItem>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((item) => {
              const { title, path } = item;
              const isActiveSub = active(path);

              return (
                <StyledNavSubItem
                  key={title}
                  component={RouterLink}
                  to={path}
                  sx={{
                    ...(isActiveSub && activeSubStyle),
                    padding: "0 6px",
                  }}
                >
                  <StyledNavItemIcon>
                    <Box
                      component="span"
                      sx={{
                        width: 4,
                        height: 4,
                        display: "flex",
                        borderRadius: "50%",
                        alignItems: "center",
                        justifyContent: "center",
                        bgcolor: "text.disabled",
                        transition: (theme) => theme.transitions.create("transform"),
                        ...(isActiveSub && {
                          transform: "scale(2)",
                          bgcolor: "primary.main",
                        }),
                      }}
                    />
                  </StyledNavItemIcon>
                  <ListItemText primary={title} />
                </StyledNavSubItem>
              );
            })}
          </List>
        </Collapse>
      </div>
    );
  }

  return (
    <StyledNavItem
      component={RouterLink}
      to={path}
      // onClick={() => setHasChildren(false)}
      sx={{
        // '&.active': {
        //   color: 'text.primary',
        //   bgcolor: 'action.selected',
        //   fontWeight: 'fontWeightBold',
        // },
        ...(isActiveRoot && activeRootStyle),
      }}
    >
      <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>
      <ListItemText primary={title} />
      {info && info}
    </StyledNavItem>
  );
}
