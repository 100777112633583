import PropTypes from 'prop-types';
import { useState } from 'react';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import {
  Checkbox,
  FormControlLabel as MUiFormLabel,
  FormGroup,
  FormHelperText,
  Radio,
  RadioGroup,
  Typography,
  styled,
} from '@mui/material';

// ----------------------------------------------------------------------

RHFCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
};

export function RHFCheckbox({ name, ...other }) {
  const { control } = useFormContext();

  return (
    <FormControlLabel
      control={
        <Controller
          name={name}
          control={control}
          render={({ field }) => <Checkbox {...field} checked={field.value} />}
        />
      }
      {...other}
    />
  );
}

// ----------------------------------------------------------------------

RHFMultiCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
};

export function RHFMultiCheckbox({ name, options, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const onSelected = (option) =>
          field.value.includes(option) ? field.value.filter((value) => value !== option) : [...field.value, option];

        return (
          <>
            <FormGroup>
              {options.map((option) => (
                <FormControlLabel
                  key={option.value}
                  control={
                    <Checkbox
                      checked={field.value.includes(option.value)}
                      onChange={() => field.onChange(onSelected(option.value))}
                    />
                  }
                  label={option.label}
                  {...other}
                />
              ))}
              {console.log(error, 'checkboxerr')}
            </FormGroup>
            <Typography>hello</Typography>
          </>
        );
      }}
    />
  );
}

const FormControlLabel = styled(MUiFormLabel)(({ theme }) => ({
  '& .MuiFormControlLabel-label': {
    fontWeight: 600,
    color: theme.palette.text.secondary,
    fontSize: '0.875rem !important',
  },
}));

RHFMultiRadio.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
};

export function RHFMultiRadio({ name, options, row, boolean, ...other }) {
  const { control, setValue } = useFormContext();

  function handleBoolean(value) {
    if (value.toLowerCase() === 'true') {
      setValue(name, true);
    } else {
      setValue(name, false);
    }
  }

  function handleValue(value) {
    setValue(name, value);
  }
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <>
            <RadioGroup
              row={row}
              defaultValue={field.value}
              value={field.value}
              onChange={
                boolean
                  ? (e) => {
                      handleBoolean(e.target.value);
                    }
                  : (e) => {
                      handleValue(e.target.value);
                    }
              }
              sx={{ minHeight: 56 }}
            >
              {options.map((option) => (
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  control={<Radio />}
                  label={option.label}
                  sx={{
                    '& .MuiFormControlLabel-label': {
                      color: (theme) => field.value === option.value && theme.palette.primary.main,
                    },
                  }}
                  {...other}
                />
              ))}
            </RadioGroup>
            {error && (
              <FormHelperText sx={{ color: (theme) => theme.palette.error.main }}>{error.message}</FormHelperText>
            )}
          </>
        );
      }}
    />
  );
}
