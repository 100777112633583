export const BusRouteData = [
  {
    division: "DPS Sharjah Primary",
    divisioncode: "1",
    children: [
      {
        area: "SHARJAH SCHOOL AREA",
        areacode: "1",
        children: [
          {
            route: "SHARJAH SCHOOL AREA/MUWAILAH COMMERCIAL",
            routeCode: "16",
            buses: [123, 126, 132, 135, 144, 151, 170],
          },
        ],
      },
      {
        area: "SHARJAH",
        areacode: "2",
        children: [
          {
            route: "AL NAHDA SHARJAH",
            routeCode: "1",
            buses: [123, 126, 132, 135, 144, 151, 170],
          },
          {
            route: "AL QASIMIYA",
            routeCode: "2",
            buses: [121, 137, 140],
          },
          {
            route: "AL MAHATTA",
            routeCode: "3",
            buses: [142, 121, 146],
          },
          {
            route: "ABU SHAGARA",
            routeCode: "4",
            buses: [125, 145, 136],
          },
          {
            route: "AL MAJAZ 3 / BUHAIRAH CORNICHE",
            routeCode: "5",
            buses: [128, 148],
          },
          {
            route: "AL MAJAZ 2",
            routeCode: "6",
            buses: [124],
          },
          {
            route: "AL MAJAZ 1",
            routeCode: "7",
            buses: [150],
          },
          {
            route: "J.A.N Street",
            routeCode: "8",
            buses: [139],
          },
          {
            route: "ROLLA / BANK STREET",
            routeCode: "9",
            buses: [131],
          },
          {
            route: "ROLLA SQUARE / AL RAIS ",
            routeCode: "10",
            buses: [131],
          },
          {
            route: "NABBA / MAYSALOON / MUSALLA",
            routeCode: "11",
            buses: [147],
          },
          {
            route: "RAMLA / FAHYA / YARMOOK",
            routeCode: "12",
            buses: [152],
          },
          {
            route: "BUTINA / AL QULAYA'H",
            routeCode: "13",
            buses: [153],
          },
          {
            route: "ROLLA / BANK STREET",
            routeCode: "14",
            buses: [131],
          },
          {
            route: "AL TAWUUN",
            routeCode: "15",
            buses: [143, 167],
          },
        ],
      },
    ],
  },
  {
    division: "DPS Sharjah Senior",
    divisioncode: "2",
    children: [],
  },
  {
    division: "DPS Sharjah Dubai",
    divisioncode: "3",
    children: [],
  },
  {
    division: "DPS Sharjah RAK",
    divisioncode: "4",
    children: [],
  },
];

export function getBusesOnRoute(data, divisionCode, areaCode, routeCode) {
  let buses = [];

  if (divisionCode === "0" && areaCode === "0" && routeCode === "0") {
    data.forEach((division) =>
      division.children.forEach((area) =>
        area.children.forEach((route) =>
          route.buses.forEach((bus) =>
            buses.push({
              busNumber: bus,
              route: route.route,
              area: area.area,
              division: division.division,
            })
          )
        )
      )
    );
  } else {
    const division = data.find((route) => route.divisioncode === divisionCode);
    if (division) {
      if (areaCode === "0" && routeCode === "0") {
        division.children.forEach((area) =>
          area.children.forEach((route) =>
            route.buses.forEach((bus) =>
              buses.push({
                busNumber: bus,
                route: route.route,
                area: area.area,
                division: division.division,
              })
            )
          )
        );
      } else {
        const area = division.children.find((area) => area.areacode === areaCode);
        if (area) {
          if (routeCode === "0") {
            area.children.forEach((route) =>
              route.buses.forEach((bus) =>
                buses.push({
                  busNumber: bus,
                  route: route.route,
                  area: area.area,
                  division: division.division,
                })
              )
            );
          } else {
            const route = area.children.find((route) => route.routeCode === routeCode);
            if (route) {
              route.buses.forEach((bus) =>
                buses.push({
                  busNumber: bus,
                  route: route.route,
                  area: area.area,
                  division: division.division,
                })
              );
            }
          }
        }
      }
    }
  }

  return buses;
}
