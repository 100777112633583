import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import useResponsive from "hooks/useResponsive";

const DropList = ({ items }) => {
  const mdDown = useResponsive("down", "md");
  return (
    <div>
      {items.map((item, index) => (
        <Accordion key={index} defaultExpanded>
          {/* Set defaultExpanded to true */}
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index + 1}-content`}
            id={`panel${index + 1}-header`}
          >
            <Typography
              variant={mdDown ? "h6" : "h5"}
              fontWeight="400"
              fontFamily='"Merriweather", serif'
            >
              {item.label}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1">{item.description}</Typography>
          </AccordionDetails>
          {item.actions && (
            <AccordionActions>
              {item.actions.map((action, actionIndex) => (
                <Button key={actionIndex}>{action}</Button>
              ))}
            </AccordionActions>
          )}
        </Accordion>
      ))}
    </div>
  );
};

export default DropList;
