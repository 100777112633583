import React from "react";
// @mui
import { Typography, useTheme, Button, Container, Stack } from "@mui/material";
import Iconify from "components/iconify";

// styled components
import { RootStyle, DataContainer } from "./styles";
import useResponsive from "hooks/useResponsive";

const DefaultHeading = "This is the Heading";
const DefaultDescription =
  "Et cupidatat sint id sunt sunt anim adipisicing irure amet ut qui do. Culpa consectetur culpa qui aliquip duis. Aliqua pariatur magna fugiat consequat. Consectetur non laborum veniam esse est nulla. Occaecat laborum irure Lorem et voluptate culpa ad adipisicing. Cupidatat esse deserunt sint culpa proident non deserunt est consectetur ut. Laboris consectetur exercitation tempor nulla consectetur incididunt commodo nulla anim ad aliquip aliquip veniam consequat.";

function HeroSection({
  heading = DefaultHeading,
  description = DefaultDescription,
  img,
  white = false,
  action = null,
  headerWidth = "100%",
}) {
  const theme = useTheme();
  const mdDown = useResponsive("down", "md");

  return (
    <div style={{ background: white && theme.palette.background.paper }}>
      <Container maxWidth="sm" sx={{ py: 10 }}>
        <div style={{ width: "100%", height: "100%", width: headerWidth }}>
          <Typography
            variant={mdDown ? "h3" : "h1"}
            textAlign="center"
            variantMapping={{
              div: "h4",
            }}
            fontWeight="700"
          >
            {heading}
          </Typography>
          <div style={{ maxWidth: 800, margin: "0 auto" }}>
            <Typography variant="body1" textAlign="center" mt={2} color="text.secondary">
              {description}
            </Typography>
          </div>
        </div>

        {action && (
          <Stack alignItems="center" style={{ maxWidth: 800, margin: "36px auto 0 auto" }}>
            <Button
              size="large"
              variant="contained"
              onClick={action?.execute}
              z
              startIcon={<Iconify icon={action?.icon} width={28} />}
            >
              {action?.title}
            </Button>
          </Stack>
        )}
        {img && (
          <DataContainer sx={{ mt: 5, minHeight: 200, width: "100%" }}>
            <img src={img} alt="feature image" style={{ maxWidth: "75%" }} />
          </DataContainer>
        )}
      </Container>
    </div>
  );
}

export default HeroSection;
