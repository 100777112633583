import { Types } from '../actionTypes';


const initialState = {
    date: new Date(),
    punchstate: "out",
    lastPunch: { date: new Date(), type: "out" },
    validGeofence: true,

}
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case Types.ATT_TOGGLE:
            if (state.punchstate === "out") {
                return {
                    ...state,
                    punchstate: "in",
                    lastPunch: { date: new Date(), type: "out" }
                }
            }
            if (state.punchstate === "in") {
                return {
                    ...state,
                    punchstate: "out",
                    lastPunch: { date: new Date(), type: "in" }
                }
            }

            break;

        default:
            return state;
    }

    return "";
}


export default reducer;