import React from "react";
// @mui
import { Stack, Typography, useTheme } from "@mui/material";

// styled components
import { ImageContainer, ImageContainer2, RootStyle, DataContainer } from "./styles";
import useResponsive from "hooks/useResponsive";

function HeroSection({ id }) {
  const theme = useTheme();
  const mdDown = useResponsive("down", "md");

  return (
    <RootStyle id={id}>
      <DataContainer>
        <Typography
          variant={mdDown ? "h3" : "h1"}
          textAlign="center"
          fontWeight="700"
          fontFamily='"Merriweather", serif'
        >
          Your Trusted Partner for Safe School and Charter Bus Services in Dubai and Sharjah
        </Typography>
        <Typography variant="body1" textAlign="center" mt={2} mx={4}>
          NAJMAT AL NAJAH SCHOOL BUS TRANSPORTATION LLC (NBT) began its journey as a dedicated bus
          transport company, catering to the unique requirements of school transportation and
          charter bus services in the vibrant cities of Dubai and Sharjah.
        </Typography>
      </DataContainer>
      <ImageContainer />
      <ImageContainer2 />
    </RootStyle>
  );
}

export default HeroSection;
