import { Outlet } from "react-router-dom";
import React, { useEffect, useState } from "react";

// material
import { styled } from "@mui/material/styles";
import Navbar from "./navbar";
import NavDrawer from "../drawernav"
const RootStyle = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
});

const MainStyle = styled("div")(() => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  paddingTop: 64
}));

export default function Layout() {

  const [open, setOpen] = useState(false);
  const handleDrawerOpen = () => {
    setOpen(true)
  }
  const handleDrawerClose = () => {
    setOpen(false)
  }
  return (
    <RootStyle>
      <MainStyle>
        <Navbar handleDrawerOpen={handleDrawerOpen}/>
        <NavDrawer openNav={open} handleDrawerClose={handleDrawerClose} />
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
}
