import { IconButton, Stack,Button } from "@mui/material";
import { styled } from "@mui/material/styles";
// import { LoadingButton } from "@mui/lab";

// image
import HeroImage from "assets/images/hero/about-us-left-ex.svg";
import HeroImage2 from "assets/images/hero/about-us-right-ex.svg";

export const RootStyle = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: theme.palette.background.paper,
  padding: theme.spacing(0, 1),
  paddingTop: theme.spacing(15),
  overflow: "visible",
  zIndex: 2,

  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(0, 5),
    paddingTop: theme.spacing(15),
  },
  [theme.breakpoints.up("lg")]: {
    padding: theme.spacing(0, 20),
    paddingTop: theme.spacing(15),
  },
}));

export const ImageContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "800px",
  flex: 1,
  backgroundImage: `url(${HeroImage})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "100%",
  backgroundPosition: "bottom left",
  opacity: 0.6,
}));

export const ImageContainer2 = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "800px",
  flex: 1,
  backgroundImage: `url(${HeroImage2})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "100%",
  backgroundPosition: "bottom",
  opacity: 0.6,
}));

export const DataContainer = styled(Stack)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  maxWidth: 600,
}));

export const StyledSearchButton = styled(Button)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  borderRadius: theme.shape.borderRadius,
  background: theme.palette.primary.main,

  [theme.breakpoints.up("md")]: {
    borderRadius: "100%",
    padding: "20px 10px",
    width: "100px !important",
  },
  [theme.breakpoints.up("lg")]: {
    borderRadius: "100%",
  },
}));
