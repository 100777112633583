import React from "react";
import { useTheme } from "@mui/material/styles";
// sections
import { HeroSection, Feesection, DueDate } from "Sections/PaymentModes";
import { FeatureWithSteps, SingleColFeature, CallToAction } from "Sections/features";

import { RealTimeTracking, VisionConfig, SafetyConfig, CTAConfig } from "./config";

function Home() {
  const theme = useTheme();

  return (
    <>
      <HeroSection id="TheHero" />
      <DueDate
        heading={<>{SafetyConfig.head}</>}
        description={SafetyConfig.desc}
        zero
        steps={SafetyConfig.steps}
      />

      <Feesection id="fees-section" white={false} />

      <SingleColFeature
        heading={RealTimeTracking.head}
        description={RealTimeTracking.desc}
        img={RealTimeTracking.img}
        action={RealTimeTracking?.action}
        white
      />
      <SingleColFeature
        heading={VisionConfig.head}
        description={VisionConfig.desc}
        action={VisionConfig?.action}
        img={VisionConfig?.img}
      />

      {/* <CallToAction heading={CTAConfig.head} description={CTAConfig.desc} /> */}
    </>
  );
}

export default Home;
