import React from "react";

import { useNavigate } from "react-router-dom";
import Image404 from "assets/images/illustrations/404-01.svg";
import { Stack, Container, Button } from "@mui/material";

function Page404() {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/");
  }

  return (
    <div style={{ background: "#fff" }}>
      <Container maxWidth="xl">
        <Stack flex={1} justifyContent="center" alignItems="center" minHeight="100vh">
          <img src={Image404} style={{ maxWidth: 600 }} />
          <Button variant="contained" onClick={handleButtonClick}>Go Home</Button>
        </Stack>
      </Container>
    </div>
  );
}

export default Page404;
