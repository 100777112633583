import { AppBar, Toolbar, ListItemIcon, ListItemButton } from "@mui/material";
import { styled } from "@mui/material/styles";

export const DRAWER_WIDTH = 0;
export const APPBAR_MOBILE = 80;
export const APPBAR_DESKTOP = 80;

export const RootStyle = styled(AppBar)(({ theme }) => {
  return {
    boxShadow: "none",
    backdropFilter: "blur(6px)",
    WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.up("lg")]: {
      width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
    },
  };
});

export const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  maxHeight: 80,

  [theme.breakpoints.up("lg")]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

export const LogoContainer = styled("div")(({ theme }) => {
  return {
    transition: "0.1s linear",
    maxWidth: 150,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
});

export const StyledNavItem = styled((props) => <ListItemButton disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.subtitle2,
    padding: theme.spacing(1),
    position: "relative",
    textTransform: "capitalize",
    color: theme.palette.text.primary,
    borderRadius: 13,
    margin: "0 auto",
    fontSize: 14,
    marginRight: theme.spacing(2),
  })
);

export const StyledNavSubItem = styled((props) => <ListItemButton disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.body2,
    height: 38,
    position: "relative",
    textTransform: "capitalize",
    color: theme.palette.text.primary,
    borderRadius: 13,
    padding: 14,
    margin: "0 auto",
    fontSize: 13,
  })
);

export const StyledNavItemIcon = styled(ListItemIcon)({
  minWidth: 0,
  width: 20,
  height: 20,
  color: 'inherit',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: "0 12px"
});
