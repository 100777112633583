export const Types = {
    LOGIN: 'LOGIN',
    LOGOUT: 'LOGOUT',
    ATT_TOGGLE: "ATT_TOGGLE",
    SYNC_SHIFTS: "SYNC_SHIFT",
    SET_CURRENTLOCATION: 'SET_CURRENTLOCATION',
    SET_ALLGEOFENCE: "SET_ALLGEOFENCE",
    SET_GEOFENCE_VALIDITY: "SET_GEOFENCE_VALIDITY",
    LOAD_COMPANY: "LOAD_COMPANY",

    SET_PROFILE: "SET_PROFILE",
    SET_TOKEN: "SET_TOKEN"
}