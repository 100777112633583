import React from "react";
import { useTheme } from "@mui/material/styles";
// sections
import { HeroSection } from "Sections/Home";
import { FeatureWithSteps, SingleColFeature, CallToAction } from "Sections/features";

import { FeaturesConfig, IdentityConfig, SafetyConfig, CTAConfig } from "./config";
import AboutUs from "../AboutUs/index";

function Home() {
  const theme = useTheme();
  return (
    <>
      <HeroSection id="TheHero" />
      <AboutUs />
      {/* <FeatureWithSteps
        white={true}
        heading={
          <>
            ABOUT US
            <br /> <span style={{ color: theme.palette.primary.main }}> Dubai & Sharjah</span>{" "}
          </>
        }
        description={IdentityConfig.desc}
        // headerWidth={1000}
      />
      <SingleColFeature
        heading={
          <>
            {FeaturesConfig.head}
            <span style={{ color: theme.palette.primary.main }}> Amazing Service</span>.
          </>
        }
        description={FeaturesConfig.desc}
        img={FeaturesConfig.img}
      />
      <FeatureWithSteps
        heading={
          <>
            {SafetyConfig.head}
            <span style={{ color: theme.palette.primary.main }}> Top Priorities</span>.
          </>
        }
        description={SafetyConfig.desc}
        steps={SafetyConfig.steps}
      />

      <CallToAction heading={CTAConfig.head} description={CTAConfig.desc} /> */}
    </>
  );
}

export default Home;
