import React from "react";
import { useTheme } from "@mui/material/styles";
// sections
import { HeroSection } from "Sections/AboutUs";
import { FeatureWithSteps, SingleColFeature, CallToAction } from "Sections/features";

import {
  WhyNBTConfig,
  VisionConfig,
  SafetyConfig,
  CTAConfig,
  AboutConfig,
  MissionConfig,
  ObjectiveConfig,
  ClientConfig,
  MSGConfig,
} from "./config";
import GmMessage from "Sections/features/GmMessage";
import { ClientsList } from "Sections/features";
import TestimonialSlider from "./TestimonalSlider";

function Home() {
  const theme = useTheme();
  return (
    <div id="about">
      {/* <HeroSection id="TheHero" /> */}
      <FeatureWithSteps heading={<>{AboutConfig.head}</>} description={AboutConfig.desc} white changeContainer={false} imageUrl="https://firebasestorage.googleapis.com/v0/b/najmat-fcf50.appspot.com/o/images%2Fc3.jpg?alt=media&token=9e38306a-0c9c-41ba-9856-274033783c37" imageOnLeft={false}/>

      <FeatureWithSteps heading={<>{VisionConfig.head}</>} description={VisionConfig.desc} />
      <FeatureWithSteps
        heading={<>{MissionConfig.head}</>}
        description={MissionConfig.desc}
        white
        changeContainer={false}
        imageUrl="https://firebasestorage.googleapis.com/v0/b/najmat-fcf50.appspot.com/o/images%2Fc1.jpg?alt=media&token=eb180df7-3d38-4a71-8e00-a750c18b373f" 
      />
      {/* <FeatureWithSteps heading={<>{ObjectiveConfig.head}</>} description={ObjectiveConfig.desc} /> */}
      {/* <SingleColFeature heading={WhyNBTConfig.head} description={WhyNBTConfig.desc} white /> */}

      <GmMessage heading={MSGConfig.head} description={MSGConfig.desc} />
      {/* <FeatureWithSteps
        heading={<>{SafetyConfig.head}</>}
        description={SafetyConfig.desc}
        steps={SafetyConfig.steps}
        zero
      /> */}
      <ClientsList heading={<>{ClientConfig.head}</>} steps={ClientConfig.steps} zero />
      {/* <CallToAction heading={CTAConfig.head} description={CTAConfig.desc} /> */}
      <TestimonialSlider/>
    </div>
  );
}

export default Home;
