import React from "react";
// @mui
import { Stack, Typography, Card, Button, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { handleDownloadTraForm } from "hooks/downloads";

// styled components
import { RootStyle, WhiteContainer } from "./styles";
import Iconify from "components/iconify";
import useResponsive from "hooks/useResponsive";

const DefaultHeading = "This is the Heading";
const DefaultDescription =
  "Et cupidatat sint id sunt sunt anim adipisicing irure amet ut qui do. Culpa consectetur culpa qui aliquip duis. Aliqua pariatur magna fugiat consequat. Consectetur non laborum veniam esse est nulla. Occaecat laborum irure Lorem et voluptate culpa ad adipisicing. Cupidatat esse deserunt sint culpa proident non deserunt est consectetur ut. Laboris consectetur exercitation tempor nulla consectetur incididunt commodo nulla anim ad aliquip aliquip veniam consequat.";

function CallToAction({ heading = DefaultHeading, description = DefaultDescription, img }) {
  const navigate = useNavigate();
  const theme = useTheme();
  const mdDown = useResponsive("down", "md");
  const handleClickContact = () => {
    navigate("/contact-us");
  };

  return (
    <RootStyle sx={{ px: 1, minHeight: 2, py: 10 }}>
      <Card sx={{ p: mdDown ? 2 : 10, maxWidth: mdDown ? "100%" : "80%" }}>
        <Stack
          direction={{ xs: "column", md: "row" }}
          spacing={3}
          style={{ width: "100%", height: "100%" }}
        >
          <Stack flex={1.5}>
            <Typography variant={mdDown ? "h4" : "h2"} fontWeight="700">
              {heading}
            </Typography>
            <Typography variant="body1" mt={2} color="text.secondary">
              {description}
            </Typography>
          </Stack>
          <Stack
            direction={{ xs: "column", md: "row" }}
            spacing={2}
            py={{ xs: 1, md: 6 }}
            justifyContent="center"
            alignItems="center"
            flex={1}
          >
            <Button size="large" fullWidth variant="contained" onClick={handleClickContact}>
              Contact Us
            </Button>

            <Button
              size="large"
              fullWidth
              variant="outlined"
              onClick={handleDownloadTraForm}
              startIcon={<Iconify icon="ic:baseline-download" />}
            >
              Transport Form
            </Button>
          </Stack>
        </Stack>
      </Card>
      <WhiteContainer />
    </RootStyle>
  );
}

export default CallToAction;
