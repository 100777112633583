import { useNavigate } from "react-router-dom";

// @mui
import { Button, useTheme } from "@mui/material";

export default function RoutesBtn({ nav }) {
  const theme = useTheme();
  const navigate = useNavigate();

  const OnclickBtn = () => {
    navigate("/contact-us");
  };
  return (
    <Button
      size={nav ? "medium" : "large"}
      fullWidth={!nav}
      variant="contained"
      onClick={OnclickBtn}
      sx={{ color: theme.palette.common.white }}
    >
      Contact Us
    </Button>
  );
}
