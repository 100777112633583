/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router components
import { useLocation } from "react-router-dom";

import { ToastContainer } from "react-toastify";
// routes
import Router from "./routes/index";
import { Provider } from "react-redux";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';

// Material Kit 2 React themes
import theme from "assets/theme";

import store from "./redux/store/store";
import axios from "axios";

import "react-toastify/dist/ReactToastify.css";
import { HelmetProvider } from "react-helmet-async";

// setting up base uri for axios
axios.defaults.baseURL = process.env.REACT_APP_BASE_API_URL;

export default function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  return (
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        {/* <Provider store={store}> */}
        <LocalizationProvider dateAdapter={AdapterMoment}>

          <CssBaseline />
          <Router />
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            />
          <CssBaseline />
            </LocalizationProvider>
        {/* </Provider> */}
      </ThemeProvider>
    </HelmetProvider>
  );
}
