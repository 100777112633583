import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// ----------------------------------------------------------------------

RHFTextField.propTypes = {
  name: PropTypes.string,
};

export default function RHFTextField({
  name,
  size,
  min,
  time,
  disabled,
  defaultVal,
  InputProps,
  variant,
  label,
  ...other
}) {
  useEffect(() => {
    if (defaultVal) {
      defaultVal();
    }
  }, []);

  const { control, setValue } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        // <TextField
        //   {...field}
        //   type={time ? "time" : "date"}
        //   fullWidth
        //   value={typeof field.value === 'number' && field.value === 0 ? '' : field.value}
        //   error={!!error}
        //   helperText={error?.message}
        //   {...other}
        // />

        <DatePicker
          // value={value}
          {...field}
          onChange={(newValue) => {
            setValue(name, newValue);
          }}
          inputFormat="DD/MM/YYYY"
          minDate={min}
          renderInput={(params) => (
            <TextField
              {...params}
              // variant={variant}
              variant={variant}
              label={label}
              fullWidth
              size={size}
              contentEditable={false}
              error={!!error}
              onBlur={()=>!!error}
              helperText={error?.message}
              disabled={disabled && disabled}
              InputProps={{
                notched: true,
                disableUnderline: true,
                ...params.InputProps,
                ...InputProps,
              }}
            />
          )}
          {...other}
        />
      )}
    />
  );
}
