export const Fleetconfig = {
    head: "Fleet Workshop ",
    desc:  `Our workshop is equipped with skilled and experienced personnel capable of precisely understanding your needs. They are committed to lighten all kinds of issues you encounter with your vehicles, no matter how much complex they seem.
    Our team is vibrant, dedicated, well trained and do the task "First Time Right" Our technicians have the complete skill set to carry out every sort of repairing works. We also have advanced tools, technologies, machinery & an ERP system that guarantee quality service.
    Waiting time is minimum as we have enough and more resources to ensure the repairing works are completed within shortest turnaround time.`
    ,
    imageUrl: "https://firebasestorage.googleapis.com/v0/b/najmat-fcf50.appspot.com/o/images%2Fc2.jpg?alt=media&token=93839ac7-dcc6-4a99-8960-af9f85e088ec"
  };
export const Satistconfig = {
    head: "Committed to customer satisfaction ",
    desc:  `Aiming complete satisfaction to customers is our immediate and ultimate gool. We strive to achieve it via all ethical ways through persistent effort backed by proper planning and getting the job dons, with precise trouble shoung and wing orily the required spareparts.`
    ,
    imageUrl: "https://firebasestorage.googleapis.com/v0/b/najmat-fcf50.appspot.com/o/images%2Fc4.jpg?alt=media&token=4f2e9c0a-3477-46a8-b0d5-7b5561ec1fd0"
  };

  export const Expertconfig = {
    heading: "Committed to customer satisfaction",
    bulletPoints: [
      "MECHANICAL WORKS",
      "VEHICLE WASHING",
      "ELECTRICAL AND AIR CONDITIONING",
      "RTA PASSING WORKS",
      "PERIODIC MAINTANENCE SERVICE",
      "BUS UPHOLSTEXT",
      "QUICK OIL CHANGE AND LUBRICATION SERVICE",
      "WINDOW TINTING",
      "WATER SERVICE - SHAMPOO AND GREASING",
      "STICKER 108S",
      "SCHOOL BUS MODIFICATION WORKS AS PER RTA SPECIFICATION",
      "SEAT MODIFICATION",
    ],
    paragraph: "We use only genuine guaranteed and warranteed spareparts. Annual Service contract package for fleet customers",
  };